const location = [
    {
        type: "text",
        name: "name",
        rules: [{rule: "required", message: "validation.fieldInvalid"}],
        label: "general.name",
        required: true
    },
];

export default location;
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { list, remove} from "../../actions/resources";
import { getResourceFromId } from "../../utils/resources";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    content: {
        margin: 20,
    }
}));

function ResourceDeleteForm(props) {
    const classes = useStyles();
    const { translate } = props;
    const resource = getResourceFromId(props.resourceId);

    const handleDelete = () => {
        if(props.onRemove !== undefined) {
            props.onRemove(props.resourceId).then(() => props.list(resource));
        } else {
            props.remove(props.resourceId).then(() => props.list(resource));
        }
        props.onClose();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            className={classes.root}
            >
            {props.title && <DialogTitle id="form-dialog-title">
                {props.title}
            </DialogTitle>}
            <DialogContent className={classes.content}>
                <Typography>
                    {props.caption ?? translate("warnings.delete_resource")}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    {translate("button.cancel")}
                </Button>
                <Button onClick={handleDelete} color="secondary">
                    {translate("button.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}


const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
        resources: state.resources,
    };
};

const mapDispatchToProps = (dispatch) => ({
    remove: (id) => dispatch(remove(id)),
    list: (resource) => dispatch(list(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ResourceDeleteForm);

import React from "react";
import { connect } from "react-redux";
import { getIngredientQuantityInLiterKilo, getInventoryItemQuantityInLiterKilo } from "../../utils/quantityTypes";

function RecipeCost(props) {
    const recipe = props.item;
    const { inventories, productGroups } = props;

    const getLatestInventoryForProduct = (productReferenceId) => {
        return inventories
            .filter(inventory =>
                inventory.inventoryItems.some(
                    inventoryItem => inventoryItem.productReference["@id"] === productReferenceId))
            .reduce((previousValue, currentInventory) => {
                if (!previousValue) {
                    return currentInventory;
                }
                return previousValue ?
                    (previousValue.date < currentInventory.date ? currentInventory : previousValue) : currentInventory;
            }
                , undefined);
    }

    const getIngredientPrice = (ingredient) => {
        var inventory;
        var productReferenceId;

        // Finds the latest inventory including the ingredient's product (or product group)
        if (ingredient.productGroup) {
            const productGroup = productGroups.find(productGroup => productGroup["@id"] === ingredient.productGroup);
            inventory = productGroup.productReferences.reduce((previousValue, productRefId) => {
                const latestInventory = getLatestInventoryForProduct(productRefId);
                if (!previousValue) {
                    if (latestInventory) {
                        productReferenceId = productRefId;
                    }
                    return latestInventory;
                }
                if (latestInventory && previousValue.date < latestInventory.date) {
                    productReferenceId = productRefId;
                    return latestInventory;
                }
                return previousValue;
            }, undefined);
        } else {
            productReferenceId = ingredient.productReference;
            inventory = getLatestInventoryForProduct(productReferenceId);
        }

        if (!inventory || !productReferenceId) {
            return 0;
        }
        const inventoryItem = inventory
            .inventoryItems
            .find(inventoryItem =>
                inventoryItem.productReference["@id"] === productReferenceId);

        const ingredientQuantityInLiterKilo = getIngredientQuantityInLiterKilo(ingredient);
        const inventoryItemQuantityInLiterKilo = getInventoryItemQuantityInLiterKilo(inventoryItem);

        if (inventoryItemQuantityInLiterKilo === 0) {
            return 0;
        }

        return (ingredientQuantityInLiterKilo / inventoryItemQuantityInLiterKilo) * inventoryItem.amount;
    }

    const price = (inventories && recipe && productGroups && recipe.ingredients.reduce((previousValue, ingredient) => {
        return previousValue + getIngredientPrice(ingredient)
    }, 0)) ?? 0;

    return <>
        {price.toFixed(2)}
    </>
}

const mapStateToProps = (state) => {
    return {
        productGroups: state.resources["/product_groups"],
        locations: state.resources["/locations"],
        inventories: state.resources["/inventories"]?.filter(inventory => inventory.type === "INVOICE" || inventory.type === "INITIAL_STOCK"),
        loading: state.resources.loading,
        locationsLoading: state.resources.loadings.includes("/locations"),
        inventoriesLoading: state.resources.loadings.includes("/inventories"),
        productGroupsLoading: state.resources.loadings.includes("/product_groups"),
        error: state.resources.error,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RecipeCost);

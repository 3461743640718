const initialState = {
  loggedIn: false,
  token: null,
  rememberMe: false,
  user: null,
  userId: null,
};

export default function session(state = initialState, action) {
  switch (action.type) {
    case "LOG_IN":
      return {
        ...state,
        loggedIn: true,
        rememberMe: action.value.rememberMe,
        userId: action.value.userId,
      };
    case "LOG_OUT":
      return initialState;
    case "LOAD_USER":
      return {
        ...state,
        user: action.value,
      };
    default:
      return state;
  }
}

import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Translate } from "react-localize-redux";
import { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { resetPassword } from "../../actions/session";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    display: "flex",
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: 400,
  },
  textInput: {
    display: "flex",
    width: "100%",
  },
  signinButton: {
    display: "flex",
    width: "100%",
    height: 50,
    minWidth: 200,
    marginRight: 20,
  },
  resetPasswordError: {
    marginTop: 10,
    color: "red",
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
  },
}));

function ResetPassForm(props) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState();
  const classes = useStyles();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const validate = () => {
    const validator = new SimpleReactValidator();
    let res = true;
    if (!validator.check(email, "required|email")) {
      setEmailError(true);
      res = false;
    }

    return res;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setResetPasswordError(undefined);
    if (validate()) {
      props
        .resetPassword(email)
        .then(() => {
          if (props.onSuccess) {
            props.onSuccess();
          }
        })
        .catch((error) => {
          setResetPasswordError(error.message);
        })
        .then(() => {
          if (props.onSubmit) {
            props.onSubmit();
          }
        });
    }
  };

  const renderResetPasswordError = () => (
    <Translate>
      {({ translate }) => (
        <p className={classes.resetPasswordError}>
          {Number.parseInt(resetPasswordError) === 401
            ? translate("user.invalidCredentials")
            : translate("error.unknown")}
        </p>
      )}
    </Translate>
  );

  return (
    <div className={classes.root}>
      <Translate>
        {({ translate }) => {
          return (
            <form className={classes.form} onSubmit={onSubmit}>
              <TextField
                className={classes.textInput}
                variant="outlined"
                required
                type="email"
                id="email"
                label={translate("user.email")}
                placeholder={translate("user.email")}
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError && translate("validation.emailInvalid")}
              />
              <div className={classes.buttonRow}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.signinButton}
                >
                  {translate("user.resetPassword")}
                </Button>
                {props.onCancel && <Button
                  variant="contained"
                  color="secondary"
                  className={classes.signinButton}
                  onClick={(event) => { event.preventDefault(); props.onCancel(); }}
                >
                  {translate("button.cancel")}
                </Button>}
              </div>
              {resetPasswordError && renderResetPasswordError()}
            </form>
          );
        }}
      </Translate>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (email) =>
    dispatch(resetPassword(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassForm);

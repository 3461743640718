import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import TitledLayout from "../../theme/TitledLayout";
import CrudTable from "../utils/CrudTable";
import productGroupsDef from "../../definitions/productGroups";
import { productReferencesShort as productReferencesShortDef } from "../../definitions/productReferences";
import { list, update } from "../../actions/resources";
import ResourceAutocomplete from "../utils/ResourceAutocomplete";

const useStyles = makeStyles((theme) => ({
    crudTableContainer: {
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: 500,
    },
}));

function ProductGroups(props) {
    const classes = useStyles();
    const { translate } = props;
    const [showAddProductReferenceToGroupDialog, setShowAddProductReferenceToGroupDialog] = useState(false);
    const [selectedProductReference, setSelectedProductReference] = useState();
    const [selectedProductGroup, setSelectedProductGroup] = useState();

    console.log(selectedProductGroup, selectedProductReference);

    const handleProductReferenceDelete = (id) => {
        return props.update(id, { productGroup: null });
    }

    const AddProductReferenceToGroupDialog = () => {
        return (
            <>
                <Dialog
                    open={showAddProductReferenceToGroupDialog}
                    onClose={() => setShowAddProductReferenceToGroupDialog(false)}
                    className={classes.root}
                >
                    {props.title && <DialogTitle id="form-dialog-title">
                        {props.title}
                    </DialogTitle>}
                    <DialogContent className={classes.content}>
                        <ResourceAutocomplete
                            value={selectedProductReference}
                            onChange={(event, newValue) => setSelectedProductReference(newValue)}
                            resource={"/product_references"}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowAddProductReferenceToGroupDialog(false)} color="primary">
                            {translate("button.cancel")}
                        </Button>
                        <Button onClick={() => {
                            if(selectedProductReference) {
                                addProductReference(selectedProductGroup["@id"], selectedProductReference["@id"]);
                            }
                            setShowAddProductReferenceToGroupDialog(false);
                        }} color="primary">
                            {translate("button.save")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>);
    }

    const addProductReference = (productGroupId, productReferenceId) => {
        return props
            .update(productReferenceId, { productGroup: productGroupId })
            .then(() => props.list("/product_references"));
    }

    const handleAddProductReference = (productGroup) => {
        setShowAddProductReferenceToGroupDialog(true);
        setSelectedProductGroup(productGroup);
    }

    const renderAccordion = (productGroup) => {
        return (<CrudTable
            title={translate("productReference.productReferences")}
            resource="/product_references"
            resourceDef={productReferencesShortDef}
            filter={(productReference) => productGroup["@id"] === productReference.productGroup?.["@id"]}
            onAdd={() => handleAddProductReference(productGroup)}
            onDelete={handleProductReferenceDelete}
            minusIcon
            disableDeleteDialog
            disablePagination
        />);
    }

    return (
        <TitledLayout title={translate("productGroup.productGroups")}>
            <AddProductReferenceToGroupDialog />
            <Container className={classes.crudTableContainer}>
                <CrudTable
                    title={translate("productGroup.productGroups")}
                    resource="/product_groups"
                    resourceDef={productGroupsDef}
                    renderAccordion={renderAccordion}
                />
            </Container>
        </TitledLayout>
    )
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({
    update: (id, data) => dispatch(update(id, data)),
    list: (resource) => dispatch(list(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroups);

import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import MainLayout from "../../theme/MainLayout";
import ResourceForm from "../utils/ResourceForm";
import inventoryItemsDef from "../../definitions/inventoryItems";
import invoiceItemsDef from "../../definitions/invoiceItems";
import inventoriesDef from "../../definitions/inventories";
import CrudTable from "../utils/CrudTable";
import { useHistory, useParams } from "react-router";
import { getById } from "../../actions/resources";
import { addPrefix } from "../../utils/resources";
import invoicesDef from "../../definitions/invoices";
import InventoryItemsImporter from "../importers/InventoryItemsImporter";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
        justifyContent: "center",
    },
    content: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#020210",
        marginBottom: 20,
        padding: 20,
        borderRadius: 10,
    },
    crudTableContainer: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 20,
        marginRight: 20,
    },
    button: {
        marginRight: 10,
        width: 150,
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    formContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
}));

function InventoriesForm(props) {
    const classes = useStyles();
    const { id } = useParams();
    const [isInvoice, setIsInvoice] = useState(false);
    const inventoryId = id && addPrefix(`/inventories/${id}`);
    const {
        inventories,
        getById,
        invoicesOnly,
        loading,
        error,
        translate } = props;
    const childFormRef = useRef();
    const history = useHistory();

    useEffect(() => {
        if (!inventories && !loading && error === null) {
            getById(inventoryId);
        }

        if (inventories && inventoryId && invoicesOnly && inventories.find(inventory => inventory["@id"] === inventoryId).type !== "INVOICE") {
            history.goBack();
        }
    });

    const handleCancel = () => {
        history.goBack();
    }

    const handleSave = async () => {
        childFormRef.current.submit().then(() => history.push(invoicesOnly ? "/invoices" : "/inventories"));
    }

    const handleInventoryValuesChange = (values) => {
        if (values.type === "INVOICE" || values.type === "INITIAL_STOCK") {
            setIsInvoice(true);
        } else {
            setIsInvoice(false);
        }
    }

    return (
        <MainLayout>
            <div className={classes.root}>
                <div className={classes.content}>
                    <div className={classes.contentContainer}>
                        <InventoryItemsImporter
                            inventoryId={inventoryId}
                        />
                        <div className={classes.formContainer}>
                            {(!inventoryId || inventories) && <ResourceForm
                                ref={childFormRef}
                                data={invoicesOnly ? invoicesDef : inventoriesDef}
                                resource="/inventories"
                                resourceId={inventoryId}
                                onValueChange={handleInventoryValuesChange}
                            />}
                        </div>
                        <div className={classes.buttonsContainer}>
                            <Button className={classes.button} onClick={handleCancel} variant="contained" color="secondary">
                                {translate("button.cancel")}
                            </Button>
                            <Button className={classes.button} onClick={handleSave} variant="contained" color="primary">
                                {translate("button.save")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.crudTableContainer}>
                    <CrudTable
                        title={translate("inventory.inventoryItems")}
                        resource="/inventory_items"
                        resourceDef={isInvoice ? invoiceItemsDef : inventoryItemsDef}
                        defaultCreateValues={{ inventory: inventoryId }}
                        filter={(inventoryItem) => inventoryItem?.inventory === inventoryId}
                    />
                </div>
            </div>
        </MainLayout>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
        inventories: state.resources?.["/inventories"],
        loading: state.resources.loading,
        error: state.resources.error,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getById: (resourceId) => dispatch(getById(resourceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoriesForm);

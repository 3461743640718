export const getUnitInLiterKilo = (unit) => {
    switch (unit) {
        case "MILLILITERS":
            return 0.001;
        case "GRAMS":
            return 0.001;
        case "CENTILITERS":
            return 0.01;
        default:
            return 1.0;
    }
}
import { IconButton, makeStyles, TableCell, TableRow } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import React from "react";
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    smallCell: {
        width: 20,
        maxWidth: 80,
        padding: 8,
        flexDirection: "row",
    },
    smallIconButton: {
        padding: 6,
    },
    row: {
        display: "flex",
        flexDirection: "row",
    }
}));


function EditableTableRow(props) {
    const classes = useStyles();

    return (
        <TableRow>
            {props.children}
            <TableCell align="right" className={classes.smallCell}>
                <div className={classes.row}>
                    {!props.disableEdit && <IconButton aria-label="edit" className={classes.smallIconButton} onClick={props.onEdit}>
                        <EditIcon fontSize="small" />
                    </IconButton>}
                    {!props.disableDelete && !props.minusIcon && 
                    <IconButton aria-label="delete" className={classes.smallIconButton} onClick={props.onDelete}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>}
                    {!props.disableDelete && props.minusIcon && 
                    <IconButton aria-label="delete" className={classes.smallIconButton} onClick={props.onDelete}>
                        <RemoveCircleIcon fontSize="small" />
                    </IconButton>}
                </div>
            </TableCell>
        </TableRow>
    )
}

EditableTableRow.propTypes = {
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
};

export default EditableTableRow;
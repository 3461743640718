import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import React, { useRef } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import ResourceForm from "./ResourceForm";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    content: {
        paddingLeft: 50,
        paddingRight: 50,
    }
}));

function ResourceModal(props) {
    const classes = useStyles();
    const { translate } = props;
    const childFormRef = useRef();

    const handleSave = async (event) => {
        if(await childFormRef.current.submit(event)) {
            props.onClose();
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            className={classes.root}
            maxWidth={'lg'}
            >
            {props.title && <DialogTitle id="form-dialog-title">
                {props.title}
            </DialogTitle>}
            <DialogContent className={classes.content}>
                <ResourceForm 
                    ref={childFormRef}
                    resource={props.resource}
                    resourceId={props.resourceId}
                    data={props.data}
                    minWidth={props.minWidth}
                    onSubmitComplete={props.onClose}
                    onResourceCreated={props.onResourceCreated}
                    defaultValues={props.defaultValues}
                />
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="secondary">
                    {translate("button.cancel")}
                </Button>
                <Button onClick={handleSave} color="primary">
                    {translate("button.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceModal);

import { Container, makeStyles } from "@material-ui/core";
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import TitledLayout from "../../theme/TitledLayout";
import CrudTable from "../utils/CrudTable";
import locationsDef from "../../definitions/locations";

const useStyles = makeStyles((theme) => ({
    crudTableContainer: {
        maxWidth: 500,
    },
}));

function Locations(props) {
    const classes = useStyles();
    const { translate } = props;
    return (
        <TitledLayout title={translate("location.locations")}>
            <Container className={classes.crudTableContainer}>
            <CrudTable
                title={translate("location.locations")}
                resource="/locations"
                resourceDef={locationsDef}
             />
             </Container>
        </TitledLayout>
    )
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);

import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";

function AuthenticatedRoute(props) {
    return props.loggedIn ? <Route {...props} /> : <Redirect to={"/login"} />
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.session.loggedIn,
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute);

import React, { forwardRef } from "react";
import CrudInput from "../components/inputs/CrudInput";
import ingredients from "./ingredients";
import RecipeCost from "../components/computed/RecipeCost";

const recipes = [
    {
        type: "text",
        name: "posId",
        rules: [{ rule: "numeric|min:0,num|integer", message: "validation.fieldInvalid" }],
        label: "recipe.posId",
        numeric: true,
    },
    {
        type: "text",
        name: "name",
        rules: [{ rule: "required", message: "validation.fieldInvalid" }],
        label: "general.name",
        required: true
    },
    {
        type: "text",
        name: "description",
        rules: [],
        label: "general.description",
    },
    {
        type: "custom",
        name: "ingredients",
        label: "recipe.ingredients",
        customComponent: forwardRef((props, ref) => (<CrudInput ref={ref} {...props} resourceDef={ingredients} />)),
        noShow: true,
    },
    {
        type: "custom",
        name: "price",
        label: "recipe.cost",
        CustomComponent: RecipeCost,
        noEdit: true,
    },
];

export default recipes;
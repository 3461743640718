const inventoryTypes = [
    { value: "STANDARD", label: "inventory.standard" },
    { value: "DISCOUNT", label: "inventory.discount" },
    { value: "COMP", label: "inventory.comp" },
    { value: "INVOICE", label: "invoice.invoice" },
    { value: "INITIAL_STOCK", label: "inventory.initialStock" },
];

export default inventoryTypes;

export const parseLabel = (inventoryType, translate) => { 
    const label = inventoryTypes.find(u => inventoryType === u.value)?.label;
    return label && translate ? translate(label) : label;
}
import React from "react";
import ResourceAutocomplete from "../components/utils/ResourceAutocomplete";
import units, { parseLabel as parseUnitLabel } from "./units"

const ingredients = [
    {
        type: "custom",
        name: "productReference",
        rules: [],
        customComponent: (props) => (
            <ResourceAutocomplete
                {...props}
                resource="/product_references"
                getOptionLabel={(option, listing) => { 
                    if(option.productGroup) {
                        return listing.find(productReference => productReference?.productGroup?.["@id"] === option.productGroup).productGroup.name;
                    }
                    return listing.find(productReference => productReference["@id"] === option.productReference)?.name;
                }}
                listingToOptions={(productReferences) => {
                    return productReferences?.reduce((list, productReference) => {
                        if (list.some(item =>
                            item.productGroup &&
                            productReference.productGroup &&
                            item.productGroup === productReference.productGroup["@id"])) {
                            return list;
                        } else {
                            if(productReference.productGroup) {
                                let { productGroup } = productReference;
                                return [...list, {
                                    productGroup: productGroup["@id"],
                                }];
                            }
                            return [...list, { productReference: productReference["@id"] }];
                        }
                    }, [])
                }}
                onChange={(event, newValue) => props.onChange({ target: { value: newValue } })}
            />),
        label: "productGroup.productGroup",
        getValue: (ingredient) => {
            return ingredient.productGroup ? 
                { productGroup: ingredient.productGroup } :
                { productReference: ingredient.productReference };
        },
        setValues: (value, item) => {
            console.log("Set value", value, { ...item, ...value });
            let res = { ...item };
            if(res.productReference) {
                let { productReference, ...newRes } = res;
                res = newRes;
            }
            if(res.productGroup) {
                let { productGroup, ...newRes } = res;
                res = newRes;
            }

            return { ...res, ...value };
        },
        required: true,
    },
    {
        type: "text",
        name: "quantity",
        rules: [{ rule: "numeric|min:0,num", message: "validation.fieldInvalid" }],
        label: "general.quantity",
        numeric: true,
        required: true,
    },
    {
        type: "select",
        name: "unit",
        rules: [],
        getLabel: parseUnitLabel,
        label: "general.unit",
        options: units,
    },
];

export default ingredients;
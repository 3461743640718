import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import { Translate, withLocalize } from "react-localize-redux";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "15%",
    minHeight: 70,
    maxHeight: 100,
    paddingRight: "5%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginBottom: 3,
  },
  headerLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headerRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  menuItem: {
    marginRight: 20,
  },
  languageDropDownIcon: {
    paddingBottom: 2,
  }
}));

function AuthHeader(props) {
  const classes = useStyles();
  const [languageMenuOpen, setMenuLanguageOpen] = useState(false);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);

  const handleLanguageMenuOpen = (event) => {
    setMenuLanguageOpen(true);
    setLanguageAnchorEl(event.currentTarget);
  };
  const handleLanguageMenuClose = () => setMenuLanguageOpen(false);
  const handleLanguageClicked = (languageCode) => {
    props.setActiveLanguage(languageCode);
    handleLanguageMenuClose();
  };

  const renderLanguageMenu = () => (
    <Translate>
      {({ translate }) => (
        <div>
          <Menu id="association-menu" open={languageMenuOpen} anchorEl={languageAnchorEl}>
            <MenuItem onClick={() => handleLanguageClicked("en")}>{translate("language.english")}</MenuItem>
            <MenuItem onClick={() => handleLanguageClicked("fr")}>{translate("language.french")}</MenuItem>
          </Menu>
          <ButtonBase onClick={handleLanguageMenuOpen}>
            <Typography>{translate("welcome.language")}</Typography>
            <ArrowDropDownIcon className={classes.languageDropDownIcon} />
          </ButtonBase>
        </div>
      )}
    </Translate>
  );

  return (
    <Translate>
      {({ translate }) => {
        return (
          <Toolbar className={classes.root}>
            <div className={classes.headerLeft}>
              <Typography>Bar Back-office</Typography>
            </div>
            <div className={classes.headerRight}>
              {renderLanguageMenu()}
            </div>
          </Toolbar>
        );
      }}
    </Translate>
  );
}

export default withLocalize(AuthHeader);

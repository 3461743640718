import { Container, FormControl, FormLabel, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import TitledLayout from "../../theme/TitledLayout";
import CrudTable from "../utils/CrudTable";
import salesDef from "../../definitions/sales";
import SalesImporter from "../importers/SalesImporter";
import { clearFilters } from "../../actions/filters";
import { list } from "../../actions/resources";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    crudTableContainer: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    filtersContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 30,
        backgroundColor: "#303030",
        padding: 10,
        borderRadius: 5,
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: 20,
    },
    importer: {
        display: "flex",
        flexDirection: "column",
        justifySelf: "flex-end",
        marginLeft: 10,
    },
    filterInput: {
        marginLeft: 10,
        marginRight: 20,
        marginBottom: 10,
        minWidth: 217,
    },
}));

function Sales(props) {
    const [filtersValues, setFiltersValues] = useState({});
    const classes = useStyles();
    const { filters, clearFilters, recipes, listRecipes, sales, loading, error, translate } = props;

    useEffect(() => {
        if(!filtersValues?.startDate) {
            setFiltersValues({
                ...filtersValues,
                startDate: moment().subtract(7, 'days'),
            });
        }
        if(!filtersValues?.endDate) {
            setFiltersValues({
                ...filtersValues,
                endDate: moment(),
            });
        }
        if (filters) {
            setFiltersValues(filters);
            clearFilters();
        }
        if (!recipes && !loading && error === null) {
            listRecipes();
        }
    }, [filters, filtersValues, clearFilters, setFiltersValues, recipes, listRecipes, loading, error]);

    const renderFilters = () => {
        return (
            <div className={classes.filtersContainer}>
                <FormControl key="startDate">
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DateTimePicker
                            value={filtersValues.startDate}
                            id="startDate"
                            name={"startDate"}
                            className={classes.filterInput}
                            required={false}
                            label={translate("general.start")}
                            ampm={false}
                            format="DD-MM-yyyy HH:mm"
                            onChange={(date) => {
                                setFiltersValues({
                                    ...filtersValues,
                                    startDate: date
                                });
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl key="endDate">
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DateTimePicker
                            value={filtersValues.endDate}
                            id="endDate"
                            name={"endDate"}
                            className={classes.filterInput}
                            required={false}
                            label={translate("general.end")}
                            ampm={false}
                            format="DD-MM-yyyy HH:mm"
                            onChange={(date) => {
                                setFiltersValues({
                                    ...filtersValues,
                                    endDate: date
                                });
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
                {recipes && (<FormControl>
                    <InputLabel className={classes.filterInputLabel} shrink>
                        {translate("recipe.recipe")}
                    </InputLabel>
                    <Select
                        className={classes.filterInput}
                        value={filtersValues?.recipe ?? ""}
                        onChange={(event) => setFiltersValues({
                            ...filtersValues,
                            recipe: event.target.value,
                        })}
                        displayEmpty
                    >
                        <MenuItem value={""}>---</MenuItem>
                        {recipes.map(recipe => <MenuItem key={recipe["@id"]} value={recipe["@id"]}>{recipe.name}</MenuItem>)}
                    </Select>
                </FormControl>)
                }
                <div className={classes.importer}>
                    <SalesImporter />
                </div>
            </div>
        )
    }

    const filter = (sale) => {
        let res = true;
        if (filtersValues.recipe) {
            res = res && (sale.recipe?.["@id"] === filtersValues.recipe);
        }
        if(filtersValues.startDate) {
            res = res && moment(sale.date) >= filtersValues.startDate
        }
        if(filtersValues.endDate) {
            res = res && moment(sale.date) <= filtersValues.endDate
        }
        return res;
    }


    const filteredSales = sales?.filter(filter);
    const totalSoldAmound = filteredSales && filteredSales.reduce((acc, sale) => sale.amount + acc, 0);

    const apiFilter = filtersValues?.startDate && filtersValues?.endDate && new URLSearchParams([
        ["date[after]", filtersValues.startDate],
        ["date[before]", filtersValues.endDate],
    ]).toString();

    return (
        <TitledLayout title={translate("sale.sales")}>
            <div className={classes.root}>
                {renderFilters()}
                <FormControl>
                    <FormLabel>
                        {(filteredSales?.length > 0) && `${translate("report.total")} ($) : ${totalSoldAmound.toFixed(2)}`}
                    </FormLabel>
                </FormControl>
                <Container className={classes.crudTableContainer}>
                    {filtersValues?.startDate && filtersValues?.endDate &&
                    <CrudTable
                        title={translate("sale.sales")}
                        resource="/sales"
                        resourceDef={salesDef}
                        withCsvImporter={true}
                        filter={filter}
                        csvImporter={SalesImporter}
                        apiFilter={apiFilter}
                    />}
                </Container>
            </div>
        </TitledLayout>
    )
}

const mapStateToProps = (state) => {
    return {
        sales: state.resources["/sales"],
        translate: getTranslate(state.localize),
        filters: state.filters.sales,
        recipes: state.resources["/recipes"],
        loading: state.resources.loading,
        error: state.resources.error,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearFilters: () => dispatch(clearFilters("sales")),
    listRecipes: () => dispatch(list("/recipes")),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sales);

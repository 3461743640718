import fetch from "../utils/apiFetch";

export function loadUser() {
  return (dispatch, getState) => {
    return fetch(getState().session.userId, { method: "GET" })
      .then((res) => res.json())
      .then((json) => {
        dispatch({
          type: "LOAD_USER",
          value: json,
        });
        console.log(json);
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadUserFromCollection() {
  return (dispatch) => {
    return fetch("/users", { method: "GET" })
      .then((res) => res.json())
      .then((json) => {
        dispatch({
          type: "LOAD_USER",
          value: json["hydra:member"].pop(),
        });
        //console.log(json);
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function login({ email, password, rememberMe }) {
  return (dispatch) => {
    let headers = new Headers();
    headers.set("Accept", "application/json");
    headers.set("Content-Type", "application/json");
    return fetch("login", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        email: email,
        password: password,
        _remember_me: rememberMe ? "true" : "false",
      }),
      credentials: "include",
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.user_id) {
          const actionLogin = {
            type: "LOG_IN",
            value: {
              userId: json.user_id,
              rememberMe,
            },
          };
          return dispatch(actionLogin);
        } else {
          throw new Error(json.code.toString());
        }
      }).then(() => dispatch(loadUser()));
  };
}

export function logout() {
  return (dispatch) => {
    return fetch("logout", {
      method: "GET",
    }).then(() => {
      console.log("Logging out");
      const actionLogout = {
        type: "LOG_OUT",
      };
      dispatch(actionLogout);
    });
  };
}

export function resetPassword(email) {
  return (dispatch) => {
    return fetch("/reset_password", {
      method: "POST",
      body: JSON.stringify({ email: email }),
    }).then(() => {
      const actionResetPassword = {
        type: "RESET_PASSWORD",
      };
      dispatch(actionResetPassword);
    }).catch((error) => {
      throw error;
    });
  }
}

export function checkPassRequest({ selector, password }) {
  return (dispatch) => {
    return fetch("/check_pass_request", {
      method: "POST",
      body: JSON.stringify({ selector: selector, password: password }),
    }).then((res) => {
      const actionCheckPassRequest = {
        type: "CHECK_PASSWORD_REQUEST",
      };
      dispatch(actionCheckPassRequest);
      return res;
    }).catch((error) => {
      throw error;
    });
  }
}
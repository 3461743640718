import { ENTRYPOINT, PREFIX } from "../config/entrypoint";

const MIME_TYPE = "application/ld+json";

const fetch = (id, options) => {
  if ("undefined" === typeof options.headers) options.headers = new Headers();
  if (null === options.headers.get("Accept"))
    options.headers.set("Accept", MIME_TYPE);

  if (
    options.body &&
    !(options.body instanceof FormData) &&
    null === options.headers.get("Content-Type")
  )
    options.headers.set("Content-Type", MIME_TYPE);

  let refactoredId = id;
  const reducedPrefix = PREFIX.startsWith("/") ? PREFIX.slice(1) : PREFIX;
  if(!id.startsWith(reducedPrefix) && !id.startsWith("/" + reducedPrefix)) {
    refactoredId = reducedPrefix + (id.startsWith("/") ? "" : "/") + id;
  }
  
  return global.fetch(new URL(refactoredId, ENTRYPOINT), {...options, credentials: "include"});
};

export default fetch;

import inventoryItems from "./inventoryItems";

const invoiceItems = [
    ...inventoryItems.filter(defItem => defItem.name !== "amount"),
    {
        type: "text",
        name: "amount",
        rules: [{ rule: "numeric|min:0,num", message: "validation.fieldInvalid" }],
        label: "invoice.amount",
        numeric: true,
    },
];

export default invoiceItems;
import { FormControl, FormLabel, Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { loadUserFromCollection } from "../../actions/session";
import TitledLayout from "../../theme/TitledLayout";
import ResourceForm from "../utils/ResourceForm";
import userDef from "../../definitions/user";
import { getById } from "../../actions/resources";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    }
}));

function Profile(props) {
    const classes = useStyles();
    const { translate, loadUserFromCollection, loadUserResource, user, userResource } = props;
    const childFormRef = useRef();
    const [userLoaded, setUserLoaded] = useState(false);
    const [userResourceLoaded, setUserResourceLoaded] = useState(false);

    console.log("user resource", props.userResource);



    useEffect(() => {
        if (!userLoaded) {
            loadUserFromCollection();
            setUserLoaded(true);
        }
        if (user && !userResourceLoaded) {
            loadUserResource(user["@id"]);
            setUserResourceLoaded(true);
        }
    }, [user, userLoaded, loadUserFromCollection, loadUserResource, userResourceLoaded, setUserResourceLoaded, setUserLoaded]);

    console.log(user);

    return (
        <TitledLayout title={translate("user.profile")}>
            <div className={classes.root}>
                {userLoaded && <FormControl>
                    <FormLabel>
                        {translate("user.email")}
                    </FormLabel>
                    <FormLabel>
                        {user.email}
                    </FormLabel>
                </FormControl>}
                {userLoaded && user && userResourceLoaded && userResource && <>
                <ResourceForm
                    ref={childFormRef}
                    resourceId={user["@id"]}
                    resource={"/users"}
                    data={userDef}
                    minWidth={props.minWidth}
                    onSubmitComplete={() => { setUserLoaded(false); setUserResourceLoaded(false); }}
                />
                <Button onClick={() => childFormRef.current.submit()} color="primary">
                    {translate("button.submit")}
                </Button>
                </>
                }
            </div>
        </TitledLayout>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.session.user,
        userResource: state.resources["/users"],
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadUserFromCollection: () => dispatch(loadUserFromCollection()),
    loadUserResource: (userId) => dispatch(getById(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

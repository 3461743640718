import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { create, list, update } from "../../actions/resources";
import SimpleReactValidator from "simple-react-validator";
import { getResourceFromId } from "../../utils/resources";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        maxHeight: "70vh",
    },
    inputLabel: {
        paddingLeft: 14,
    },
    formControl: {
        padding: 10,
    },
    textField: {
        minWidth: 200,
    },
    dropdown: {
        minWidth: 200,
    },
}));

const ResourceForm = forwardRef((props, ref) => {
    const classes = useStyles();
    const {
        resource = getResourceFromId(props.resourceId),
        resourceId,
        data,
        minWidth,
        onValueChange,
        defaultValues,
        onSubmitComplete,
        onResourceCreated,
        preventSubmit,
        resources,
        translate,
        list,
        create,
        update,
        loading,
        error
    } = props;
    const [values, setFormValues] = useState(defaultValues ?? {});
    const [errors, setFormErrors] = useState({});
    const customComponentsRef = useRef({});

    console.log("values", values);

    useEffect(() => {
        if (!loading && error === null) {
            data.forEach((item) => {
                if (item.dropdownResource && !resources[item.dropdownResource]) {
                    list(item.dropdownResource);
                }
            });
        }
    });

    const submit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        if (validate()) {
            if (resourceId) {
                await update(resourceId, values).then(() => list(resource));
            } else if (resource) {
                await create(resource, values)
                    .then(() => (onResourceCreated && onResourceCreated(resources.created)))
                    .then(() => list(resource));
            }
            if (onSubmitComplete) {
                onSubmitComplete();
            }
            return true;
        }
        return false;
    }

    useImperativeHandle(ref,
        () => ({ submit }));

    const validate = () => {
        const validator = new SimpleReactValidator();
        let res = true;
        Object.values(customComponentsRef.current).forEach((cRef) => {
            if (cRef?.validate) {
                res = res && cRef.validate();
            }
        });

        data.filter(item => !item.noEdit).forEach(item => {
            if (item.rules) {
                item.rules.forEach((rule) => {
                    if (!validator.check(values[item.name], rule.rule)) {
                        setFormErrors({
                            ...errors,
                            [item.name]: rule.message ? translate(rule.message) : translate("validation.fieldInvalid")
                        });
                        res = false;
                    }
                });
            }
        });

        return res;
    };

    const handleValueChange = (newValues) => {
        setFormValues(newValues);
        if (onValueChange) {
            onValueChange(newValues);
        }
    }

    const renderCustomComponent = (item) => {
        let defaultValue = defaultValues?.[item.name];
        const retrieved = resources?.[resource]?.find(res => res["@id"] === resourceId);
        const retrievedValue = item.getValue ? item.getValue(retrieved) : retrieved?.[item.name];
        defaultValue = defaultValue ?? retrievedValue ?? item.defaultValue;
        if (values[item.name] === undefined && defaultValue) {
            handleValueChange({ ...values, [item.name]: defaultValue })
        }
        console.log("ret", item.getValue ? item.getValue(retrieved) : retrieved?.[item.name]);
        const CustomComponent = item.customComponent;
        return (
            <FormControl key={item.name} className={classes.formControl}>
                {CustomComponent && <CustomComponent
                    ref={(element) => customComponentsRef.current[item.name] = element}
                    id={item.name}
                    type={item.type}
                    name={item.name}
                    value={values?.[item.name] ?? retrievedValue}
                    required={item.required ?? false}
                    label={item.label && translate(item.label)}
                    onChange={(event) => {
                        handleValueChange({
                            ...values,
                            [item.name]: event.target.value,
                        });
                    }}
                    error={errors?.[item.name] ? true : false}
                    helperText={errors?.[item.name] ?? undefined}
                />}
            </FormControl>
        );
    };

    const renderTextField = (item) => {
        let defaultValue = defaultValues?.[item.name];
        defaultValue = defaultValue ?? resources?.[resource]?.find(res => res["@id"] === resourceId)?.[item.name] ?? item.defaultValue;
        if (values[item.name] === undefined && defaultValue) {
            handleValueChange({ ...values, [item.name]: defaultValue })
        }
        return (
            <FormControl key={item.name} className={classes.formControl}>
                <TextField
                    id={item.name}
                    type={item.type}
                    name={item.name}
                    className={classes.textField}
                    required={item.required ?? false}
                    label={item.label && translate(item.label)}
                    defaultValue={defaultValue}
                    hidden={item.hiddenCondition && item.hiddenCondition(values)}
                    onChange={(event) => {
                        handleValueChange({
                            ...values,
                            [item.name]: item.numeric ?
                                (isNaN(event.target.value) ?
                                    event.target.value :
                                    parseFloat(event.target.value)) :
                                event.target.value
                        });
                    }}
                    error={errors?.[item.name] ? true : false}
                    helperText={errors?.[item.name] ?? undefined} />
            </FormControl>
        );
    };

    const renderDateTime = (item) => {
        let defaultValue = defaultValues?.[item.name];
        defaultValue = defaultValue ?? resources?.[resource]?.find(res => res["@id"] === resourceId)?.[item.name] ?? item.defaultValue;
        defaultValue = defaultValue ?? new Date();
        if (values[item.name] === undefined && defaultValue) {
            handleValueChange({ ...values, [item.name]: defaultValue })
        }
        return (
            <FormControl key={item.name} className={classes.formControl}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DateTimePicker
                        value={values?.[item.name]}
                        id={item.name}
                        type={item.type}
                        name={item.name}
                        className={classes.textField}
                        required={item.required ?? false}
                        label={item.label && translate(item.label)}
                        ampm={false}
                        format="DD-MM-yyyy HH:mm"
                        onChange={(date) => {
                            handleValueChange({
                                ...values,
                                [item.name]: date
                            });
                        }}
                        error={errors?.[item.name] ? true : false}
                        helperText={errors?.[item.name] ?? undefined} />
                </MuiPickersUtilsProvider>
            </FormControl>
        );
    };

    const renderResourceDropdown = (item) => {
        let defaultValue = defaultValues?.[item.name];
        defaultValue = defaultValue ?? resources?.[resource]?.find(res => res["@id"] === resourceId)?.[item.name];
        defaultValue = defaultValue?.["@id"] ?? defaultValue;

        const options = resources?.[item.dropdownResource];

        if (values[item.name] === undefined && defaultValue) {
            handleValueChange({ ...values, [item.name]: defaultValue });
        } else if (values[item.name] === undefined && item.required && options?.length) {
            handleValueChange({ ...values, [item.name]: options?.[0]?.["@id"] });
        }
        return (
            <FormControl key={item.name} className={classes.formControl}>
                {item.label && (<InputLabel className={classes.inputLabel} shrink>
                    {translate(item.label)}
                </InputLabel>)}
                <Select
                    value={values?.[item.name]}
                    className={classes.dropdown}
                    defaultValue={defaultValue ?? item.defaultValue}
                    onChange={(event) => { handleValueChange({ ...values, [item.name]: event.target.value }); }}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {!item.required && (<MenuItem value={null}>{"---"}</MenuItem>)}
                    {options?.map(dRes => {
                        return (<MenuItem value={dRes["@id"]}>{item.dropdownResourceView(dRes)}</MenuItem>);
                    })}
                </Select>
            </FormControl>
        );
    };

    const renderOption = (item) => {
        let defaultValue = defaultValues?.[item.name];
        defaultValue = defaultValue ?? resources?.[resource]?.find(res => res["@id"] === resourceId)?.[item.name] ?? item.defaultValue;
        if (values[item.name] === undefined && defaultValue) {
            handleValueChange({ ...values, [item.name]: defaultValue })
        } else if (values[item.name] === undefined && item.required && item?.options?.length) {
            handleValueChange({ ...values, [item.name]: item.options?.[0]?.value });
        }
        return (
            <FormControl key={item.name} className={classes.formControl}>
                {item.label && (<InputLabel className={classes.inputLabel} shrink>
                    {translate(item.label)}
                </InputLabel>)}
                <Select
                    value={values?.[item.name]}
                    className={classes.dropdown}
                    defaultValue={defaultValue ?? item.defaultValue}
                    onChange={(event) => { handleValueChange({ ...values, [item.name]: event.target.value }); }}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {!item.required && (<MenuItem value={null}>{"---"}</MenuItem>)}
                    {item.options?.map(option => {
                        return (<MenuItem value={option.value}>{translate(option.label)}</MenuItem>);
                    })}
                </Select>
            </FormControl>
        );
    };

    return (
        <form
            className={classes.root}
            onSubmit={preventSubmit ? (event) => event.preventDefault() : submit}
            style={{ minWidth: minWidth }}
        >
            {data.filter(item => !(item.disableCondition && item.disableCondition(values)))
                .filter(item => !item.noEdit).map(item => {
                    if (item.type === "dropdown") {
                        return renderResourceDropdown(item);
                    }
                    if (item.type === "custom") {
                        return renderCustomComponent(item);
                    }
                    if (item.type === "select") {
                        return renderOption(item);
                    }
                    if (item.type === "datetime") {
                        return renderDateTime(item);
                    }
                    return renderTextField(item);
                })}
        </form>
    );
});

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
        resources: state.resources,
        loading: state.resources.loading,
        error: state.resources.error,
    };
};

const mapDispatchToProps = (dispatch) => ({
    create: (resource, data) => dispatch(create(resource, data)),
    update: (id, data) => dispatch(update(id, data)),
    list: (resource) => dispatch(list(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ResourceForm);

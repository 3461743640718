import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import clsx from 'clsx';
import { Accordion, AccordionDetails, AccordionSummary, AppBar, CssBaseline, Link, ListItemText, Toolbar, Typography } from "@material-ui/core";
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Translate } from "react-localize-redux";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import WidgetsIcon from '@material-ui/icons/Widgets';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    color: 'whitesmoke'
  },
  hide: {
    display: 'none',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette['gray-dark'],
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: 10,
    backgroundColor: theme.palette.background.default,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  menuItem: {
    padding: 0,
  },
  menuLink: {
    '&:hover': {
      color: 'inherit',
    },
  },
  menuAccordion: {
    width: "100%",
    boxShadow: "none",
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      background: 'inherit',
    },
    "&.MuiAccordion-root::before": {
      height: 0,
    }
  },
  menuAccordionSummary: {
    paddingLeft: 0,
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginTop: 10,
      marginBottom: 10,
    },
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 50,
      maxHeight: 50,
    },
  },
  menuAccordionDetails: {
    padding: 0,
    paddingLeft: 20,
  },
  menuAccordionList: {
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuAccordionText: {
    paddingLeft: 10,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menuIcon: {
    marginRight: 10,
    marginBottom: 2,
  }
}));

function MainLayout(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  }

  const drawerContentData = [
    [{ icon: (<MenuBookIcon className={classes.menuIcon} />), title: 'productReference.productReferences', route: '/product_references' },
    { icon: (<LocalDrinkIcon className={classes.menuIcon} />), title: 'productGroup.productGroups', route: '/product_groups' },
    { icon: (<WidgetsIcon className={classes.menuIcon} />), title: 'category.categories', route: '/categories' },
    { icon: (<LocalBarIcon className={classes.menuIcon} />), title: 'recipe.recipes', route: '/recipes' },
    ],
    [{ icon: (<BusinessIcon className={classes.menuIcon} />), title: 'location.locations', route: '/locations' }, 
    { icon: (<LocalShippingIcon className={classes.menuIcon} />), title: 'vendor.vendors', route: '/vendors' }],
    [{ icon: (<DescriptionIcon className={classes.menuIcon} />), title: 'inventory.inventories_and_sales', 
      submenu: [{ title: 'inventory.inventories', route: '/inventories' }, { title: 'invoice.invoices', route: '/invoices' }, { title: 'sale.sales', route: '/sales' }] }],
    [{ icon: (<BusinessIcon className={classes.menuIcon} />), title: 'report.reports', route: '/reports' }],
  ];

  const renderAccordion = (data) => {
    return (
      <Translate>
        {({ translate }) => (
          <Accordion className={classes.menuAccordion}>
            <AccordionSummary
              className={classes.menuAccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              {data.icon && data.icon}
              <Typography>{translate(data.title)}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.menuAccordionDetails}>
              <List className={classes.menuAccordionList}>
                {data.submenu.map(item => (<ListItem button key={item.title}>
                  <Link href={item.route} className={classes.menuLink} color="inherit">
                    <ListItemText primary={translate(item.title)} className={classes.menuAccordionText} />
                  </Link>
                </ListItem>)
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        )}
      </Translate>)
  }

  const renderDrawer = () => {
    return (<Drawer
      className={classes.drawer}
      variant="persistent"
      classes={{
        paper: classes.drawerPaper,
      }}
      open={open}
    >
      <Toolbar />
      <Translate>
        {({ translate }) => (
          <div className={classes.drawerContainer}>
            {drawerContentData.map((menuSection, index) => (
              <div key={`menuSection${index}`}>
                <List>
                  {menuSection.map(item => (
                    <ListItem key={item.title}>
                      {item.submenu ? renderAccordion(item) : (
                        <>
                        {item.icon && item.icon}
                        <Link href={item.route} className={classes.menuLink} color="inherit">
                          <ListItemText primary={translate(item.title)} />
                        </Link>
                        </>
                      )}
                    </ListItem>)
                  )}
                </List>
                <Divider />
              </div>
            ))}
            <Divider />
          </div>)}
      </Translate>
    </Drawer>);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar} position="fixed">
        <Header handleDrawerToggle={handleDrawerToggle}
        />
      </AppBar>
      {renderDrawer()}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Toolbar />
        {props.children}
      </main>
    </div>
  );
}

export default MainLayout;

import { Container, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import TitledLayout from "../../theme/TitledLayout";
import CrudTable from "../utils/CrudTable";
import recipesDef from "../../definitions/recipes";
import { list } from "../../actions/resources";

const useStyles = makeStyles((theme) => ({
    crudTableContainer: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

function Recipes(props) {
    const classes = useStyles();
    const { translate, listLocations, listInventories, listProductGroups } = props;

    useEffect(() => {
        listLocations();
        listInventories();
        listProductGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TitledLayout title={translate("recipe.recipes")}>
            <Container className={classes.crudTableContainer}>
            <CrudTable
                title={translate("recipe.recipes")}
                resource="/recipes"
                resourceDef={recipesDef}
                minDialogWidth={"50vw"}
             />
             </Container>
        </TitledLayout>
    )
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({
    listInventories: () => dispatch(list("/inventories")),
    listLocations: () => dispatch(list("/locations")),
    listProductGroups: () => dispatch(list("/product_groups")),
});

export default connect(mapStateToProps, mapDispatchToProps)(Recipes);

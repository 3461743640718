import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import TitledLayout from "../../theme/TitledLayout";
import CrudTable from "../utils/CrudTable";
import inventoriesDef from "../../definitions/inventories";
import invoicesDef from "../../definitions/invoices";
import { useHistory } from "react-router-dom";
import inventoryTypes from "../../definitions/inventoryTypes";
import MetroPdfUploader from "../utils/MetroPdfUploader";

const useStyles = makeStyles((theme) => ({
    crudTableContainer: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    root: {
        display: "flex",
        flexDirection: "column",
    },
    filterInput: {
        minWidth: 217,
        marginLeft: 10,
        marginRight: 20,
        marginBottom: 10,
    },
    filtersContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        marginBottom: 30,
        backgroundColor: "#303030",
        padding: 10,
        borderRadius: 5,
    },
    filterInputLabel: {
        paddingLeft: 15,
    },
    invoiceUploader: {
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 10,
    },
}));

function Inventories(props) {
    const classes = useStyles();
    const history = useHistory();
    const [filtersValues, setFiltersValues] = useState({});

    const { translate } = props;

    const handleEdit = (id) => {
        history.push(`${props.invoicesOnly ? "invoices" : "inventories"}/${id.split("/").pop()}`);
    }


    const renderFilters = () => {
        return (
            <div className={classes.filtersContainer}>
                <div>
                <TextField
                    className={classes.filterInput}
                    label={translate("general.search")}
                    value={filtersValues?.search ?? ""}
                    onChange={(event) => setFiltersValues({ ...filtersValues, search: event.target.value })}
                />
                {!props.invoicesOnly && <FormControl>
                    <InputLabel className={classes.filterInputLabel} shrink>
                        {translate("general.type")}
                    </InputLabel>
                    <Select
                        className={classes.filterInput}
                        value={filtersValues?.type ?? null}
                        onChange={(event) => setFiltersValues({
                            ...filtersValues,
                            type: event.target.value,
                        })}
                        displayEmpty
                    >
                        <MenuItem value={null}>---</MenuItem>
                        {inventoryTypes.map(type => <MenuItem key={type.value} value={type.value}>{translate(type.label)}</MenuItem>)}
                    </Select>
                </FormControl>}
                </div>
                <div>
                    {props.invoicesOnly &&
                        <MetroPdfUploader />}
                </div>
            </div>
        )
    }

    const filter = (inventory) => {
        let res = true;
        if (filtersValues.type) {
            res = res && inventory.type === filtersValues.type;
        }
        return res;
    }

    return (
        <TitledLayout title={props.invoicesOnly ? translate("invoice.invoices") : translate("inventory.inventories")}>
            <div className={classes.root}>
                {renderFilters()}
                <div className={classes.crudTableContainer}>
                    <CrudTable
                        title={translate("inventory.inventories")}
                        resource="/inventories"
                        resourceDef={props.invoicesOnly ? invoicesDef : inventoriesDef}
                        onEdit={handleEdit}
                        filter={props.invoicesOnly ? ((inventory) => (inventory.type === "INVOICE")) : filter}
                        search={filtersValues?.search}
                    />
                </div>
            </div>
        </TitledLayout>
    )
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Inventories);

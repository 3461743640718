import React, { useCallback, useEffect, useRef, useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { Checkbox, FormControl, FormLabel, Input, InputLabel, ListItemText, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import { list } from "../../actions/resources";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        rowGap: 20,
    },
    startEndRow: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        columnGap: 20,
        rowGap: 20,
    },
    groupByLocationFormControl: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    groupByLocationCheckbox: {
        marginBottom: 0
    },
    multiSelect: {
        width: 400,
    }
}));


const ReportInventoriesSelector = (props) => {
    const classes = useStyles();
    const currentStartingInventoryOnly = useRef(false);
    const currentInvoices = useRef();
    const {
        translate,
        standardInventories,
        discountAndComps,
        sales,
        invoices,
        loading,
        error,
        listStandardInventories,
        listIntermediateInventories,
        listAfterInventories,
        listIntermediateSales,
        listAfterSales,
        startingInventory,
        startingInventoryOnly = false,
        endingInventory,
        groupByLocation,
        selectedInvoices,
        selectedDiscountAndComps,
        setStartingInventory,
        setEndingInventory,
        setGroupByLocation,
        setSelectedInvoices,
        setSelectedDiscountAndComps,
        setSales,
    } = props;
    const [updateSales, setUpdateSales] = useState(false);

    const loadIntermediateInventories = useCallback((sInventory, eInventory = undefined) => {
        if(startingInventoryOnly) {
            listAfterInventories(sInventory.date);
        } else {
            listIntermediateInventories(sInventory.date, eInventory.date);
        }
    }, [startingInventoryOnly, listAfterInventories, listIntermediateInventories]);

    const loadIntermediateSales = useCallback((sInventory, eInventory = undefined) => {
        if(startingInventoryOnly) {
            listAfterSales(sInventory.date).then(() => {
                setUpdateSales(true);
            });
        } else {
            listIntermediateSales(sInventory.date, eInventory.date).then(() => {
                setUpdateSales(true);
            });            
        }
    }, [startingInventoryOnly, listAfterSales, listIntermediateSales, setUpdateSales]);

    const handleStartingInventoryChange = useCallback((inventory) => {
        const inventoriesValid = inventory && (startingInventoryOnly || (endingInventory && endingInventory.date > inventory?.date));
        if (endingInventory && endingInventory.date < inventory?.date) {
            setEndingInventory(undefined);
        }
        setStartingInventory(inventory);
        if(inventoriesValid) {
            loadIntermediateInventories(inventory, endingInventory);
            loadIntermediateSales(inventory, endingInventory);
        }
    }, [setEndingInventory, loadIntermediateInventories, loadIntermediateSales, startingInventoryOnly, setStartingInventory, endingInventory])

    const handleEndingInventoryChange = (inventory) => {
        const inventoriesValid = inventory && startingInventory && startingInventory.date < inventory?.date;
        setEndingInventory(inventory);
        if(inventoriesValid) {
            loadIntermediateInventories(startingInventory, inventory);
            loadIntermediateSales(startingInventory, inventory);
        }
    };

    const getLabelInventory = (inventory) => {
        return `${inventory["@id"].split("/").pop().padStart(4, "0")} --- ${moment(inventory.date).format('DD-MM-YYYY HH:mm')} --- ${inventory.description}`
    }

    useEffect(() => {
        if(startingInventoryOnly !== currentStartingInventoryOnly.current) {
            if(startingInventory) {
                handleStartingInventoryChange(startingInventory);
            }
            currentStartingInventoryOnly.current = startingInventoryOnly;
        }
        if (!standardInventories && !loading && error === null) {
            listStandardInventories();
        }
        if(JSON.stringify(invoices) !== JSON.stringify(currentInvoices.current)) {
            currentInvoices.current = invoices;
            setSelectedInvoices(invoices);
        }
        if(!selectedInvoices && invoices) {
            setSelectedInvoices(invoices);
        }
        if(!selectedDiscountAndComps && discountAndComps) {
            setSelectedDiscountAndComps(discountAndComps);
        }
        if(sales && updateSales) {
            setSales(sales);
            setUpdateSales(false);
        }
    }, [standardInventories, startingInventoryOnly, startingInventory, handleStartingInventoryChange, selectedInvoices, selectedDiscountAndComps, discountAndComps, invoices, setSelectedInvoices, setSelectedDiscountAndComps, loading, error, listStandardInventories, sales, setSales, updateSales]);

    return (
        <form className={classes.root}>
            <div className={classes.startEndRow}>
                <FormControl>
                    <Autocomplete
                        id="starting-inventory"
                        options={standardInventories?.filter(inventory => {
                            return (inventory && endingInventory) ? (inventory.date < endingInventory.date) : true
                        }) ?? []}
                        getOptionLabel={getLabelInventory}
                        getOptionSelected={(option, value) => option["@id"] === value["@id"]}
                        style={{ minWidth: 400 }}
                        value={startingInventory ?? null}
                        onChange={(event, newValue) => handleStartingInventoryChange(newValue)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                required={true}
                                label={translate("report.startingInventory")}
                                variant="outlined" />}
                    />
                </FormControl>
                {!startingInventoryOnly && (<FormControl>
                    <Autocomplete
                        id="ending-inventory"
                        options={standardInventories?.filter(inventory => (inventory && startingInventory) ? (inventory.date > startingInventory.date) : true) ?? []}
                        getOptionLabel={getLabelInventory}
                        style={{ minWidth: 400 }}
                        value={endingInventory ?? null}
                        onChange={(event, newValue) => handleEndingInventoryChange(newValue)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                required={true}
                                label={translate("report.endingInventory")}
                                variant="outlined" />}
                    />
                </FormControl>)}
                <FormControl className={classes.groupByLocationFormControl}>
                    <FormLabel className={classes.groupByLocationCheckbox}>{translate("report.groupByLocation")}</FormLabel>
                    <Checkbox
                        value={groupByLocation}
                        onChange={(event) => { setGroupByLocation(event.target.checked)}}
                    />
                </FormControl>
            </div>
            <div className={classes.startEndRow}>
            {startingInventory && (endingInventory || startingInventoryOnly) && invoices && selectedInvoices && <FormControl className={classes.formControl}>
                    <InputLabel id="mutiple-checkbox-label">{translate("invoice.invoices")}</InputLabel>
                    <Select
                        labelId="mutiple-checkbox-label"
                        id="mutiple-checkbox"
                        multiple
                        value={selectedInvoices}
                        onChange={(event) => { setSelectedInvoices(event.target.value); }}
                        input={<Input />}
                        renderValue={(selected) => selected.map(invoice => getLabelInventory(invoice)).join(', ')}
                        className={classes.multiSelect}
                    >
                        {invoices.map((invoice) => (
                            <MenuItem key={invoice["@id"]} value={invoice}>
                                <Checkbox checked={selectedInvoices.indexOf(invoice) > -1} />
                                <ListItemText primary={getLabelInventory(invoice)} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
                {startingInventory && (endingInventory || startingInventoryOnly) && discountAndComps && selectedDiscountAndComps && <FormControl className={classes.formControl}>
                    <InputLabel id="mutiple-checkbox-label">{`${translate("inventory.comp")} & ${translate("inventory.discount")}`}</InputLabel>
                    <Select
                        labelId="mutiple-checkbox-label"
                        id="mutiple-checkbox"
                        multiple
                        value={selectedDiscountAndComps}
                        onChange={(event) => { setSelectedDiscountAndComps(event.target.value); }}
                        input={<Input />}
                        renderValue={(selected) => selected.map(discountAndComp => getLabelInventory(discountAndComp)).join(', ')}
                        className={classes.multiSelect}
                    >
                        {discountAndComps.map((discountAndComp) => (
                            <MenuItem key={discountAndComp["@id"]} value={discountAndComp}>
                                <Checkbox checked={selectedDiscountAndComps.indexOf(discountAndComp) > -1} />
                                <ListItemText primary={getLabelInventory(discountAndComp)} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
            </div>
        </form>
    );
};

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
        loading: state.resources.loading,
        error: state.resources.error,
        standardInventories: state.resources["/inventories"]?.filter(inventory => inventory.type === "STANDARD" || inventory.type === "INITIAL_STOCK"),
        invoices: state.resources["/intermediates"]?.filter(inventory => inventory.type === "INVOICE"),
        discountAndComps: state.resources["/intermediates"]?.filter(inventory => inventory.type === "DISCOUNT" || inventory.type === "COMP"),
        sales: state.resources["/intermediate_sales"],
    };
};

const mapDispatchToProps = (dispatch) => {
    const standardInventoriesParams = new URLSearchParams([
        ["type[]", "STANDARD"],
        ["type[]", "INITIAL_STOCK"],
        ["order[date]", "desc"]
    ]);
    const getIntermediateInventoriesParams = (startDate, endDate) => {
        return new URLSearchParams([
            ["date[after]", startDate],
            ["date[before]", endDate],
            ["type[]", "DISCOUNT"],
            ["type[]", "INVOICE"],
            ["type[]", "COMP"],    
            ["order[date]", "desc"],
        ]);
    }

    const getAfterInventoriesParams = (startDate) => {
        return new URLSearchParams([
            ["date[after]", startDate],
            ["type[]", "DISCOUNT"],
            ["type[]", "INVOICE"],
            ["type[]", "COMP"],    
            ["order[date]", "desc"],
        ]);
    }

    return ({
        listStandardInventories: () => dispatch(list("/inventories?" + standardInventoriesParams.toString())),
        listIntermediateInventories: (startDate, endDate) => dispatch(list("/inventories?" + getIntermediateInventoriesParams(startDate, endDate).toString(), "/intermediates")),
        listAfterInventories: (startDate) => dispatch(list("/inventories?" + getAfterInventoriesParams(startDate).toString(), "/intermediates")),
        listIntermediateSales: (startDate, endDate) => dispatch(list("/sales_stats?" + getIntermediateInventoriesParams(startDate, endDate).toString(), "/intermediate_sales")),
        listAfterSales: (startDate) => dispatch(list("/sales_stats?" + getAfterInventoriesParams(startDate).toString(), "/intermediate_sales")),
    });
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportInventoriesSelector);

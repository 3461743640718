import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, makeStyles } from "@material-ui/core";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import CsvImporter from "./CsvImporter";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
    root: {
    },
    content: {
        paddingLeft: 50,
        paddingRight: 50,
    },
    button: {

    }
}));

const columnsDef = [
    {
        name: "registerId",
        label: "sale.registerId",
        rules: ["numeric|min:0,num|integer", "required"],
        required: true,
        parse: Number.parseInt,
    },
    {
        name: "posId",
        label: "recipe.posId",
        rules: ["numeric|min:0,num|integer", "required"],
        required: true,
        resource: "/recipes",
        resourceField: "posId",
    },
    {
        name: "numberSold",
        label: "sale.numberSold",
        rules: ["numeric|min:0,num", "required"],
        required: true,
        parse: Number.parseFloat,
    },
    {
        name: "amount",
        label: "sale.amount",
        rules: ["numeric|min:0,num"],
        parse: (str) => Number.parseFloat(Number.parseFloat(str).toFixed(2)),
    },
    {
        name: "date",
        label: "general.date",
        required: true,
        validate: (str) => { console.log(moment(str).format()); return moment(str).isValid() },
        parse: (str) => moment(str).format(),
    },
];

function SalesImporter(props) {
    const classes = useStyles();
    const { translate } = props;
    const [dialogVisible, setDialogVisible] = useState(false);
    const [fullscreen, setFullScreen] = useState(false);
    const childRef = useRef();


    useEffect(() => {
        window.moment = moment;
    });

    const handleSave = async (event) => {
        await childRef.current.submit({ inventory: props.inventoryId })
        setDialogVisible(false);
        setFullScreen(false);
        window.location.reload(true);
    }

    return (
        <>
            <Dialog
                open={dialogVisible}
                onClose={() => {
                    if(props.onClose) { 
                        props.onClose(); 
                    } setFullScreen(false); 
                }}
                className={classes.root}
                fullScreen={fullscreen}
            >
                {props.title && <DialogTitle id="form-dialog-title">
                    {props.title}
                </DialogTitle>}
                <DialogContent className={classes.content}>
                    <CsvImporter
                        ref={childRef}
                        columnsDef={columnsDef}
                        mainResource="/sales"
                        identifier="registerId"
                        onCsvImported={() => setFullScreen(true)}
                        onCsvDeleted={() => setFullScreen(false)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogVisible(false)} color="secondary">
                        {translate("button.cancel")}
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        {translate("button.save")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                onClick={() => setDialogVisible(true)}
                variant="contained"
                className={classes.button}
            >
                {translate("importer.csvImport")}
            </Button>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesImporter);

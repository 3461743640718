import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { list } from "../../actions/resources";

function ResourceAutocomplete(props) {
  const listing = props.resources[props.resource]
    ?.filter((listingItem) => props.filter ? props.filter(listingItem) : true);
  const options = listing && (props.listingToOptions ? props.listingToOptions(listing) : listing);

  useEffect(() => {
    if (!listing && !props.loading && props.error === null) {
      props.list(props.resource);
    }
  });

  return (
    <>
      {listing && <Autocomplete
        id="resource-autocomplete"
        options={options}
        getOptionLabel={(option) => props.getOptionLabel ? props.getOptionLabel(option, listing) : option["name"]}
        style={{ minWidth: 300 }}
        value={props.value}
        onChange={props.onChange}
        renderInput={(params) =>
          <TextField
            {...params}
            required={props.required}
            label={props.label}
            helperText={props.helperText}
            error={props.error}
            variant="outlined" />}
      />}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.resources.loading,
    error: state.resources.error,
    resources: state.resources,
  };
};

const mapDispatchToProps = (dispatch) => ({
  list: (resource) => dispatch(list(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourceAutocomplete);

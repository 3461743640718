import React, { useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, makeStyles } from "@material-ui/core";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import CsvImporter from "./CsvImporter";


const useStyles = makeStyles((theme) => ({
    root: {
    },
    content: {
        paddingLeft: 50,
        paddingRight: 50,
    },
    button: {

    }
}));

const columnsDef = [
    {
        name: "name",
        label: "general.name",
        required: true,
        rules: ["required"],
    },
    {
        name: "productCode",
        label: "productReference.productCode",
        rules: [],
    },
    {
        name: "subcategory",
        label: "subcategory.subcategory",
        resource: "/subcategories",
        resourceField: "name",
        rules: [],
    },
    {
        name: "productGroup",
        label: "productGroup.productGroup",
        resource: "/product_groups",
        resourceField: "name",
        rules: [],
    },
    {
        name: "capacity",
        label: "productReference.capacity",
        rules: ["numeric|min:0,num"],
        parse: Number.parseFloat,
    },
    {
        name: "unit",
        label: "general.unit",
        parse: (value) => {
            const upValue = value.toUpperCase();
            switch (upValue) {
                case "ML": case "MILLILITER":
                    return "MILLILITERS";
                case "L": case "LITER":
                    return "LITERS";
                case "G": case "GRAM":
                    return "GRAMS";
                case "KG": case "KILOGRAM":
                    return "KILOGRAMS";
                default:
                    return "MILLILITERS";
            }
        },
        validate: (value) => {
            const upValue = value.toUpperCase();
            switch (upValue) {
                case "ML": case "MILLILITER":
                    return true;
                case "L": case "LITER":
                    return true;
                case "G": case "GRAM":
                    return true;
                case "KG": case "KILOGRAM":
                    return true;
                default:
                    return false;
            }
        }
    },
    {
        name: "doseCapacity",
        label: "productReference.doseCapacity",
        parse: Number.parseFloat,
    },
    {
        name: "doseCapacityUnit",
        label: "productReference.doseCapacityUnitLong",
        parse: (value) => {
            const upValue = value.toUpperCase();
            switch (upValue) {
                case "ML": case "MILLILITER":
                    return "MILLILITERS";
                case "L": case "LITER":
                    return "LITERS";
                case "G": case "GRAM":
                    return "GRAMS";
                case "KG": case "KILOGRAM":
                    return "KILOGRAMS";
                default:
                    return "MILLILITERS";
            }
        },
        validate: (value) => {
            const upValue = value.toUpperCase();
            switch (upValue) {
                case "ML": case "MILLILITER":
                    return true;
                case "L": case "LITER":
                    return true;
                case "G": case "GRAM":
                    return true;
                case "KG": case "KILOGRAM":
                    return true;
                default:
                    return false;
            }
        }
    },
    {
        name: "empty",
        label: "productReference.empty",
        rules: ["numeric|min:0,num"],
        parse: Number.parseFloat,
    },
    {
        name: "full",
        label: "productReference.full",
        rules: ["numeric|min:0,num"],
        parse: Number.parseFloat,
    },
];

function ProductReferencesImporter(props) {
    const classes = useStyles();
    const { translate } = props;
    const [dialogVisible, setDialogVisible] = useState(false);
    const [fullscreen, setFullScreen] = useState(false);
    const childRef = useRef();

    const handleSave = async (event) => {
        if (await childRef.current.submit()) {
            setDialogVisible(false);
            setFullScreen(false);
        }
    }

    return (
        <>
            <Dialog
                open={dialogVisible}
                onClose={() => {
                    if(props.onClose) { 
                        props.onClose(); 
                    } setFullScreen(false); 
                }}                className={classes.root}
                fullScreen={fullscreen}
            >
                {props.title && <DialogTitle id="form-dialog-title">
                    {props.title}
                </DialogTitle>}
                <DialogContent className={classes.content}>
                    <CsvImporter
                        ref={childRef}
                        columnsDef={columnsDef}
                        mainResource="/product_references"
                        identifier="name"
                        onCsvImported={() => setFullScreen(true)}
                        onCsvDeleted={() => setFullScreen(false)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogVisible(false)} color="secondary">
                        {translate("button.cancel")}
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        {translate("button.save")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                onClick={() => setDialogVisible(true)}
                variant="contained"
                className={classes.button}
            >
                {translate("importer.csvImport")}
            </Button>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductReferencesImporter);

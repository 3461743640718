const unitTypes = [{ value: "BOTTLE", label: "unitType.bottle" },
{ value: "CASE", label: "unitType.case" },
{ value: "FOOD", label: "unitType.food" },
{ value: "CONTENT", label: "unitType.content" },
];

export default unitTypes;

export const parseLabel = (unitType, translate) => { 
    const label = unitTypes.find(u => unitType === u.value)?.label;
    return label && translate ? translate(label) : label;
}
import { FormControl, Select, Input, InputLabel, ListItemText, makeStyles, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { clearFilters } from "../../actions/filters";
import { list } from "../../actions/resources";
import TitledLayout from "../../theme/TitledLayout";
import ReportInventoriesSelector from "../inputs/ReportInventoriesSelector";
import ResourceAutocomplete from "../utils/ResourceAutocomplete";
import { getUnitInLiterKilo } from "../../utils/units";
import { getQuantity, getProductReferenceUnitInLiterKilo, getIngredientQuantityInLiterKilo } from "../../utils/quantityTypes";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        "& .MuiTableCell-stickyHeader": {
            top: 64,
        },
    },
    startEndRow: {
        marginBottom: 20,
    },
    formControl: {
        marginBottom: 20,
    },
    filterInput: {
        marginLeft: 10,
        marginRight: 20,
        marginBottom: 10,
        minWidth: 217,
    },
    filtersContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 30,
        backgroundColor: "#303030",
        padding: 10,
        borderRadius: 5,
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: 20,
    },
    flexCol: {
        display: "flex",
        flexDirection: "column",
    },
    filterInputLabel: {
        paddingLeft: 15,
    },
    filterInputLg: {
        marginLeft: 10,
        marginRight: 30,
        marginBottom: 10,
        minWidth: 350,
    },
}));

function Reports(props) {
    const classes = useStyles();
    const { filters, clearFilters, translate, subcategories, locations, loading, error, listLocations, listSubcategories } = props;

    const [filtersValues, setFiltersValues] = useState({});
    const [reportTypeIndex, setReportTypeIndex] = useState(0);
    const [startingInventory, setStartingInventory] = useState();
    const [endingInventory, setEndingInventory] = useState();
    const [groupByLocation, setGroupByLocation] = useState(false);
    const [selectedInvoices, setSelectedInvoices] = useState();
    const [selectedDiscountAndComps, setSelectedDiscountAndComps] = useState();
    const [sales, setSales] = useState();

    const startingInventoryOnly = (reportTypeIndex === 3);
    const hasProductReferenceSearch = (filtersValues.search?.["@id"] ?? "").length > 0;
    const hasCategorySearch = (filtersValues.category ?? "").length > 0;
    const hasSubcategorySearch = (filtersValues.subcategory ?? "").length > 0;
    const hasLocationSearch = (filtersValues.location ?? "").length > 0;

    useEffect(() => {
        if (filters) {
            setFiltersValues(filters);
            clearFilters();
        }

        if (!subcategories && !loading && error === null) {
            listSubcategories();
        }

        if (!locations && !loading && error === null) {
            listLocations();
        }
    }, [filters, subcategories, locations, listLocations, loading, error, clearFilters, listSubcategories]);

    const categories = useMemo(() => subcategories?.reduce((acc, value) => {
        return acc.some(item => item["@id"] === value.category["@id"]) ? acc : [...acc, value.category];
    }, []), [subcategories]);

    const renderFilters = () => {
        return (
            <div className={classes.filtersContainer}>
                <div className={classes.flexCol}>
                    <div>
                        {locations && (<FormControl>
                            <InputLabel className={classes.filterInputLabel} shrink>
                                {translate("location.location")}
                            </InputLabel>
                            <Select
                                className={classes.filterInput}
                                value={filtersValues?.location ?? ""}
                                onChange={(event) => setFiltersValues({
                                    ...filtersValues,
                                    location: event.target.value,
                                })}
                                displayEmpty
                            >
                                <MenuItem value={""}>---</MenuItem>
                                {locations.map(location => <MenuItem key={location["@id"]} value={location["@id"]}>{location.name}</MenuItem>)}
                            </Select>
                        </FormControl>)}
                        {categories && (<FormControl>
                            <InputLabel className={classes.filterInputLabel} shrink>
                                {translate("category.category")}
                            </InputLabel>
                            <Select
                                className={classes.filterInput}
                                value={filtersValues?.category ?? ""}
                                onChange={(event) => setFiltersValues({
                                    ...filtersValues,
                                    category: event.target.value,
                                    subcategory: (filtersValues.category === event.target.value) ? filtersValues.subcategory : ""
                                })}
                                displayEmpty
                            >
                                <MenuItem value={""}>---</MenuItem>
                                {categories.map(category => <MenuItem key={category["@id"]} value={category["@id"]}>{category.name}</MenuItem>)}
                            </Select>
                        </FormControl>)}
                        {subcategories && (
                            <FormControl>
                                <InputLabel className={classes.filterInputLabel} shrink>
                                    {translate("subcategory.subcategory")}
                                </InputLabel>
                                <Select
                                    className={classes.filterInput}
                                    value={filtersValues?.subcategory ?? ""}
                                    onChange={(event) => setFiltersValues({
                                        ...filtersValues,
                                        subcategory: event.target.value,
                                        category: subcategories.find(subcategory => subcategory["@id"] === event.target.value)?.category["@id"] ?? ""
                                    })}
                                    displayEmpty
                                >
                                    <MenuItem value={""}>---</MenuItem>
                                    {subcategories
                                        .filter(subcategory => !filtersValues.category || (subcategory.category["@id"] === filtersValues.category))
                                        .map(subcategory => <MenuItem key={subcategory["@id"]} value={subcategory["@id"]}>{subcategory.name}</MenuItem>)}
                                </Select>
                            </FormControl>)}
                        <FormControl>
                            <InputLabel className={classes.filterInputLabel} shrink>
                                {translate("productReference.productReference")}
                            </InputLabel>
                            <div className={classes.filterInputLg}>
                                <ResourceAutocomplete
                                    onChange={(event, newValue) => { setFiltersValues({ ...filtersValues, search: newValue }); }}
                                    resource={"/product_references"}
                                    filter={productReference => {
                                        if (hasSubcategorySearch) {
                                            return productReference.subcategory?.["@id"] === filtersValues.subcategory;
                                        }
                                        if (hasCategorySearch) {
                                            return productReference.subcategory?.category?.["@id"] === filtersValues.category;
                                        }
                                        return true;
                                    }}
                                />
                            </div>
                        </FormControl>
                    </div>
                </div>
            </div>
        )
    }

    const getQuantityInLiterKilo = (reportLine) => {
        return reportLine?.productReference.capacity ?
            reportLine.quantities.usage * reportLine.productReference.capacity * getProductReferenceUnitInLiterKilo(reportLine.productReference) :
            0;
    }

    const getValue = useCallback((inventoryItem, inventory) => {
        if (!selectedInvoices || selectedInvoices.length === 0) {
            return inventoryItem.amount;
        }
        if (inventoryItem.quantity === 0) {
            return 0;
        }
        const latestInvoices = [...selectedInvoices, ...(startingInventory ? [startingInventory] : [])]
            .filter(invoice => (!inventory) || invoice.date < inventory.date)
            .sort((invoiceA, invoiceB) => invoiceA.date < invoiceB.date ? 1 : -1);
        const { quantity, amount } = latestInvoices.reduce((acc, invoice) => {
            const foundInvoicedItems = invoice.inventoryItems.filter(invItem => invItem.productReference["@id"] === inventoryItem.productReference["@id"]);
            if (foundInvoicedItems.length === 0) {
                return acc;
            }
            if (acc.quantity === inventoryItem.quantity) {
                return acc;
            }
            const { quantity, amount } = foundInvoicedItems.reduce(({ quantity, amount }, invoicedItem) => ({
                quantity: quantity + getQuantity(invoicedItem),
                amount: (quantity + getQuantity(invoicedItem)) > 0 ? (quantity * amount + getQuantity(invoicedItem) * invoicedItem.amount) / (quantity + getQuantity(invoicedItem)) : 0
            }), { quantity: 0, amount: 0 });
            if (quantity === 0) {
                return acc;
            }
            if (acc.quantity + quantity <= inventoryItem.quantity) {
                return {
                    quantity: acc.quantity + quantity,
                    amount: amount + acc.amount,
                };
            } else {
                return {
                    quantity: inventoryItem.quantity,
                    amount: acc.amount + (inventoryItem.quantity - acc.quantity) * amount / quantity,
                }
            }
        }, { quantity: 0, amount: 0 });

        if (quantity === inventoryItem.quantity) {
            return amount;
        } else if (quantity === 0) {
            return inventoryItem.amount;
        } else {
            return amount / quantity * inventoryItem.quantity;
        }
    }, [selectedInvoices, startingInventory]);

    const reducedStartingInventory = useMemo(() => startingInventory && startingInventory.inventoryItems.map(inventoryItem => ({
        productReference: inventoryItem.productReference,
        quantity: getQuantity(inventoryItem),
        amount: inventoryItem.amount,
        start: 1,
        invoiced: 0,
        discountComp: 0,
        end: 0,
        stock: 1,
        usage: 1,
        sold: 0,
        location: inventoryItem.location,
    })), [startingInventory]);

    const reducedDiscountCompInventories = useMemo(() => selectedDiscountAndComps && [...selectedDiscountAndComps.map(discountOrComp =>
        discountOrComp.inventoryItems.map(inventoryItem => ({
            productReference: inventoryItem.productReference,
            quantity: getQuantity(inventoryItem),
            amount: getValue(inventoryItem),
            start: 0,
            invoiced: 0,
            discountComp: 1,
            end: 0,
            stock: -1,
            usage: -1,
            sold: 0,
            location: inventoryItem.location,
        })))].flat(), [selectedDiscountAndComps, getValue]);

    const reducedInvoices = useMemo(() => selectedInvoices && [...selectedInvoices.map(invoice =>
        invoice.inventoryItems.map(inventoryItem => ({
            productReference: inventoryItem.productReference,
            quantity: getQuantity(inventoryItem),
            amount: inventoryItem.amount,
            start: 0,
            invoiced: 1,
            discountComp: 0,
            end: 0,
            stock: 1,
            usage: 1,
            sold: 0,
            location: inventoryItem.location,
        })))].flat(), [selectedInvoices]);

    const reducedEndingInventory = useMemo(() => endingInventory && endingInventory.inventoryItems.map(inventoryItem => ({
        productReference: inventoryItem.productReference,
        quantity: getQuantity(inventoryItem),
        amount: getValue(inventoryItem),
        start: 0,
        invoiced: 0,
        discountComp: 0,
        end: 1,
        usage: -1,
        stock: 0,
        sold: 0,
        location: inventoryItem.location,
    })), [endingInventory, getValue]);

    const getReportLineByProductRef = (productReference, report) => {
        return report.find(line => productReference["@id"] === line.productReference["@id"]);
    }

    const reducedInventories = useMemo(() => startingInventoryOnly ? (startingInventory && selectedDiscountAndComps && selectedInvoices &&
        [...reducedStartingInventory, ...reducedDiscountCompInventories, ...reducedInvoices]) :
        (startingInventory && selectedDiscountAndComps && selectedInvoices && endingInventory &&
            [...reducedStartingInventory, ...reducedDiscountCompInventories, ...reducedInvoices, ...reducedEndingInventory]),
        [startingInventory, selectedDiscountAndComps, selectedInvoices, endingInventory, startingInventoryOnly, reducedDiscountCompInventories, reducedEndingInventory, reducedStartingInventory, reducedInvoices]);

    const computedReport = useMemo(() => {
        const getSoldQuantityFromSale = (productReference, sale, report) => {
            const ingredients = sale.recipe.ingredients;
            let sold = 0;
            let poured = 0;
            let recipeWholesale = 0;
            ingredients.map(ingredient => {
                let soldRatio = 1;
                let soldRatioLkg = 0;
                let ingredientUsageLkg = 0;
                let ingredientUsage = 0;
                let totalIngredientUsage = 0;
                let totalIngredientUsageLkg = 0;

                if (ingredient.productGroup) {
                    ingredient.productGroup.productReferences.forEach(productRef => {
                        const ingredientReportLine = getReportLineByProductRef(productRef, report);
                        totalIngredientUsage += Math.max(ingredientReportLine?.amounts.usage ?? 0, 0);
                        totalIngredientUsageLkg += Math.max(ingredientReportLine ? getQuantityInLiterKilo(ingredientReportLine) : 0, 0);
                        if (productRef["@id"] === productReference["@id"]) {
                            ingredientUsage += Math.max(ingredientReportLine.amounts.usage, 0);
                            ingredientUsageLkg +=  Math.max(ingredientReportLine ? getQuantityInLiterKilo(ingredientReportLine) : 0, 0);
                        }
                    });
                    soldRatio = totalIngredientUsage !== 0 ? ingredientUsage / totalIngredientUsage : 0;
                    soldRatioLkg = totalIngredientUsageLkg !== 0 ? ingredientUsageLkg / totalIngredientUsageLkg : 0;
                } else if (ingredient.productReference) {
                    const reportLine = getReportLineByProductRef(ingredient.productReference, report);
                    totalIngredientUsage = Math.max(reportLine?.amounts.usage ?? 0, 0);
                    totalIngredientUsageLkg = Math.max(getQuantityInLiterKilo(reportLine), 0);
                    ingredientUsage = ingredient.productReference["@id"] === productReference["@id"] ? totalIngredientUsage : 0;
                    ingredientUsageLkg = ingredient.productReference["@id"] === productReference["@id"] ? totalIngredientUsageLkg : 0;
                    soldRatioLkg = ingredient.productReference["@id"] === productReference["@id"] ? 1 : 0;
                }

                const ingredientWholesale = ingredientUsageLkg ? soldRatio * getIngredientQuantityInLiterKilo(ingredient) * ingredientUsage / ingredientUsageLkg : 0;
                const totalIngredientWholesale = totalIngredientUsageLkg ? getIngredientQuantityInLiterKilo(ingredient) * totalIngredientUsage / totalIngredientUsageLkg : 0;

                recipeWholesale += totalIngredientWholesale;

                return {
                    soldUnnormalized: ingredientWholesale * sale.amount,
                    pourage: soldRatioLkg * sale.numberSold * ingredient.quantity * getUnitInLiterKilo(ingredient.unit),
                };
            }).forEach(({ soldUnnormalized, pourage }) => {
                sold += (recipeWholesale !== 0) ? soldUnnormalized / recipeWholesale : 0;
                poured += pourage;
            });
            return { sold, poured };
        }
        let computedReport = reducedInventories && reducedInventories.reduce((acc, value) => {
            const productRefIndex = acc.findIndex(item => item.productReference["@id"] === value.productReference["@id"] &&
                (!groupByLocation || item.location === value.location));
            if (productRefIndex >= 0) {
                return acc.map((item, index) => {
                    return productRefIndex === index ? {
                        ...item,
                        quantities: {
                            start: item.quantities.start + value.start * value.quantity,
                            end: item.quantities.end + value.end * value.quantity,
                            invoiced: item.quantities.invoiced + value.invoiced * value.quantity,
                            discountAndComp: item.quantities.discountAndComp + value.discountAndComp * value.quantity,
                            usage: item.quantities.usage + value.usage * value.quantity,
                            stock: item.quantities.stock + value.stock * value.quantity,
                        },
                        amounts: {
                            start: item.amounts.start + value.start * value.amount,
                            end: item.amounts.end + value.end * value.amount,
                            invoiced: item.amounts.invoiced + value.invoiced * value.amount,
                            discountAndComp: item.amounts.discountAndComp + value.discountAndComp * value.amount,
                            usage: item.amounts.usage + value.usage * value.amount,
                            stock: item.amounts.stock + value.stock * value.amount,
                        },
                    } : item;
                });
            } else {
                return [...acc, {
                    productReference: value.productReference,
                    location: value.location,
                    quantities: {
                        start: value.start * value.quantity,
                        end: value.end * value.quantity,
                        invoiced: value.invoiced * value.quantity,
                        discountAndComp: value.discountAndComp * value.quantity,
                        usage: value.usage * value.quantity,
                        stock: value.stock * value.quantity,
                    },
                    amounts: {
                        start: value.start * value.amount,
                        end: value.end * value.amount,
                        invoiced: value.invoiced * value.amount,
                        discountAndComp: value.discountAndComp * value.amount,
                        usage: value.usage * value.amount,
                        stock: value.stock * value.amount,
                    }
                }];
            }
        }, []);

        if (sales) {
            computedReport = computedReport && computedReport.map(reportLine => {
                let totalSold = 0;
                let totalPoured = 0;
                sales.forEach(sale => {
                    const { sold, poured } = getSoldQuantityFromSale(reportLine.productReference, sale, computedReport);
                    totalSold += sold;
                    totalPoured += poured;
                });
                return {
                    ...reportLine, sales: {
                        sold: totalSold,
                        poured: totalPoured,
                    }
                };
            });
        }

        computedReport = computedReport && computedReport.map(reportLine => {
            const containerVolume = reportLine.productReference.capacity * getProductReferenceUnitInLiterKilo(reportLine.productReference);
            const numberSold = (containerVolume > 0 && reportLine.sales?.poured) ?
                reportLine.sales?.poured / containerVolume : 0;
            const cogs = (containerVolume > 0 && reportLine.quantities.usage > 0 && reportLine.sales?.poured) ?
                reportLine.amounts.usage / reportLine.quantities.usage * reportLine.sales?.poured / containerVolume : 0;
            const soldCapacity = reportLine.sales?.poured ?? 0;
            const soldQuantity = containerVolume > 0 ? (soldCapacity / containerVolume) : 0;
            const soldAmount = reportLine.quantities.stock > 0 ? soldQuantity * reportLine.amounts.stock / reportLine.quantities.stock : 0;
            const usageCapacity = reportLine.productReference.capacity ?
                reportLine.quantities.usage * reportLine.productReference.capacity * getProductReferenceUnitInLiterKilo(reportLine.productReference) : 0;

            return {
                ...reportLine,
                computed: {
                    cogs: cogs ?? 0,
                    numberSold: numberSold ?? 0,
                    soldCapacity: soldCapacity ?? 0,
                    soldQuantity: soldQuantity ?? 0,
                    soldAmount: soldAmount ?? 0,
                    stockCapacity: reportLine.amounts.stock * containerVolume,
                    usageCapacity: usageCapacity,
                }
            }

        });

        computedReport = computedReport && computedReport
            .sort((itemA, itemB) => {
                if (groupByLocation) {
                    if ((itemA.location["@id"] ?? "") < (itemB.location["@id"] ?? "")) return -1;
                    if ((itemA.location["@id"] ?? "") > (itemB.location["@id"] ?? "")) return 1;
                }

                if ((itemA.productReference.subcategory?.category.name ?? "") < (itemB.productReference.subcategory?.category.name ?? "")) return -1;
                if ((itemA.productReference.subcategory?.category.name ?? "") > (itemB.productReference.subcategory?.category.name ?? "")) return 1;

                if ((itemA.productReference.subcategory?.name ?? "") < (itemB.productReference.subcategory?.name ?? "")) return -1;
                if ((itemA.productReference.subcategory?.name ?? "") > (itemB.productReference.subcategory?.name ?? "")) return 1;

                if ((itemA.productReference.name ?? "") < (itemB.productReference.name ?? "")) return -1;
                if ((itemA.productReference.name ?? "") > (itemB.productReference.name ?? "")) return 1;

                return 0;
            });

        computedReport = computedReport &&
            computedReport.filter(reportLine => hasProductReferenceSearch ? reportLine.productReference["@id"] === filtersValues.search["@id"] : true);
        computedReport = computedReport &&
            computedReport.filter(reportLine => hasCategorySearch ? reportLine.productReference.subcategory?.category?.["@id"] === filtersValues.category : true);
        computedReport = computedReport &&
            computedReport.filter(reportLine => hasSubcategorySearch ? reportLine.productReference.subcategory?.["@id"] === filtersValues.subcategory : true);
        computedReport = computedReport &&
            computedReport.filter(reportLine => hasLocationSearch ? reportLine.location?.["@id"] === filtersValues.location : true);


        return computedReport;
    },
        [reducedInventories,
            sales,
            filtersValues,
            groupByLocation,
            hasProductReferenceSearch,
            hasCategorySearch,
            hasSubcategorySearch,
            hasLocationSearch,]);

    const reduceReport = useCallback((report, attributeName, attributeGetter, filters = []) => {
        let filteredReport = [...report];
        filters.forEach(filt => filteredReport = filteredReport.filter(filt));
        return report && filteredReport.reduce((acc, value) => {
            const foundIndex = acc.findIndex(item => {
                if (typeof attributeGetter(value) === "object" && typeof attributeGetter(item) === "object") {
                    return attributeGetter(item)["@id"] === attributeGetter(value)["@id"];
                } else {
                    return attributeGetter(item) === attributeGetter(value);
                }
            });
            if (foundIndex >= 0) {
                return acc.map((item, index) => foundIndex === index ? {
                    ...item,
                    quantities: {
                        start: item.quantities.start + value.quantities.start,
                        end: item.quantities.end + value.quantities.end,
                        invoiced: item.quantities.invoiced + value.quantities.invoiced,
                        discountAndComp: item.quantities.discountAndComp + value.quantities.discountAndComp,
                        usage: item.quantities.usage + value.quantities.usage,
                        stock: item.quantities.stock + value.quantities.stock,
                    },
                    amounts: {
                        start: item.amounts.start + value.amounts.start,
                        end: item.amounts.end + value.amounts.end,
                        invoiced: item.amounts.invoiced + value.amounts.invoiced,
                        discountAndComp: item.amounts.discountAndComp + value.amounts.discountAndComp,
                        usage: item.amounts.usage + value.amounts.usage,
                        stock: item.quantities.stock + value.quantities.stock,
                    },
                    sales: {
                        sold: (item.sales?.sold ?? 0) + (value.sales?.sold ?? 0),
                        poured: (item.sales?.poured ?? 0) + (value.sales?.poured ?? 0),
                    },
                    computed: {
                        cogs: (item.computed?.cogs ?? 0) + (value.computed?.cogs ?? 0),
                        numberSold: (item.computed?.numberSold ?? 0) + (value.computed?.numberSold ?? 0),
                        soldCapacity: (item.computed?.soldCapacity ?? 0) + (value.computed?.soldCapacity ?? 0),
                        soldQuantity: (item.computed?.soldQuantity ?? 0) + (value.computed?.soldQuantity ?? 0),
                        soldAmount: (item.computed?.soldAmount ?? 0) + (value.computed?.soldAmount ?? 0),
                        stockCapacity: (item.computed?.stockCapacity ?? 0) + (value.computed?.stockCapacity ?? 0),
                        usageCapacity: (item.computed?.usageCapacity ?? 0) + (value.computed?.usageCapacity ?? 0),
                    }
                } : item);
            } else {
                return [...acc, { [attributeName]: attributeGetter(value), ...value }];
            }
        }, []);
    }, []);

    const subcategoryReport = useCallback((filt) => computedReport && reduceReport(computedReport, "subcategory", (val) => val.productReference.subcategory, filt ?? []), [computedReport, reduceReport]);
    const categoryReport = useCallback((filt) => computedReport && reduceReport(computedReport, "category", (val) => val.productReference.subcategory?.category, filt ?? []), [computedReport, reduceReport]);
    const locationReport = useCallback((filt) => computedReport && reduceReport(computedReport, "location", (val) => val.location, filt ?? []), [computedReport, reduceReport]);
    const totalReport = useCallback((filt) => computedReport && reduceReport(computedReport, "total", (val) => true, filt ?? []), [computedReport, reduceReport]);

    const renderUsageQuantitiesAndAmounts = (reportLine) => (
        <>
            <TableCell>
                {reportLine.quantities.start.toFixed(2)}
            </TableCell>
            <TableCell>
                {reportLine.amounts.start.toFixed(2)}
            </TableCell>
            <TableCell>
                {reportLine.quantities.invoiced.toFixed(2)}
            </TableCell>
            <TableCell>
                {reportLine.amounts.invoiced.toFixed(2)}
            </TableCell>
            <TableCell>
                {reportLine.quantities.end.toFixed(2)}
            </TableCell>
            <TableCell>
                {reportLine.amounts.end.toFixed(2)}
            </TableCell>
            <TableCell>
                {reportLine.quantities.usage.toFixed(2)}
            </TableCell>
            <TableCell>
                {reportLine.computed.usageCapacity.toFixed(2)}
            </TableCell>
            <TableCell>
                {reportLine.amounts.usage.toFixed(2)}
            </TableCell>
        </>
    );

    const renderVarianceQuantitiesAndAmounts = (reportLine) => {
        return (
            <>
                <TableCell>
                    {reportLine.sales?.sold.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.computed.cogs.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.amounts.usage.toFixed(2)}
                </TableCell>
                <TableCell>
                    {`${(reportLine.computed.cogs - reportLine.amounts.usage).toFixed(2)} (${(reportLine.amounts.usage !== 0 ? 100 * (reportLine.computed.cogs - reportLine.amounts.usage) / reportLine.amounts.usage : 0).toFixed(2)} %)`}
                </TableCell>
            </>
        );
    }

    const renderPourCostQuantitiesAndAmounts = (reportLine) => {
        return (
            <>
                <TableCell>
                    {reportLine.computed.numberSold.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.sales?.sold.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.computed.cogs.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.amounts.usage.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.sales?.poured.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.productReference.capacity ?
                        (reportLine.quantities.usage * reportLine.productReference.capacity * getProductReferenceUnitInLiterKilo(reportLine.productReference)).toFixed(2) :
                        0}
                </TableCell>
                <TableCell>
                    {((reportLine.sales?.sold > 0) ? 100 * reportLine.amounts.usage / reportLine.sales?.sold : 0).toFixed(2)}
                </TableCell>
                <TableCell>
                    {(reportLine.sales?.sold > 0 ? 100 * reportLine.computed.cogs / reportLine.sales?.sold : 0).toFixed(2)}
                </TableCell>
                <TableCell>
                    {(reportLine.amounts.usage !== 0 ? 100 * (reportLine.computed.cogs - reportLine.amounts.usage) / reportLine.amounts.usage : 0).toFixed(2)}
                </TableCell>
            </>
        );
    }

    const renderStockQuantitiesAndAmounts = (reportLine) => {
        const soldCapacity = reportLine.sales?.poured ?? 0;
        return (
            <>
                <TableCell>
                    {reportLine.quantities.start.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.amounts.start.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.quantities.invoiced.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.computed.soldQuantity.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.sales.sold.toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.computed.soldAmount.toFixed(2)}
                </TableCell>
                <TableCell>
                    {soldCapacity.toFixed(2)}
                </TableCell>
                <TableCell>
                    {(reportLine.quantities.stock - reportLine.computed.soldQuantity).toFixed(2)}
                </TableCell>
                <TableCell>
                    {(reportLine.amounts.stock - reportLine.computed.soldAmount).toFixed(2)}
                </TableCell>
                <TableCell>
                    {reportLine.productReference.capacity ?
                        (reportLine.computed.stockCapacity - soldCapacity).toFixed(2) :
                        0}
                </TableCell>
            </>
        )
    };

    const renderCategoryTotals = (reportLineIndex, renderQuantitiesAndAmounts) => {
        const reportLine = reportLineIndex >= 0 &&
            categoryReport(groupByLocation ?
                [(report => { return report.location["@id"] === computedReport[reportLineIndex].location["@id"] })] : [])
                .find(report => report.category?.["@id"] === computedReport[reportLineIndex].productReference.subcategory?.category["@id"]);
        return (reportLineIndex >= 0 && (reportLineIndex === computedReport.length - 1 ||
            computedReport[reportLineIndex].productReference.subcategory?.category["@id"] !== computedReport[reportLineIndex + 1].productReference.subcategory?.category["@id"] ||
            (groupByLocation && computedReport[reportLineIndex].location["@id"] !== computedReport[reportLineIndex + 1].location["@id"]))) &&
            (<TableRow key={`category-totals-${reportLineIndex}`}>
                {groupByLocation && <TableCell />}
                <TableCell><b>{translate("category.category")}</b></TableCell>
                <TableCell />
                <TableCell>{computedReport[reportLineIndex].productReference.subcategory?.category.name}</TableCell>
                {renderQuantitiesAndAmounts(reportLine)}
            </TableRow>);
    }

    const renderSubcategoryTotals = (reportLineIndex, renderQuantitiesAndAmounts) => {
        const reportLine = reportLineIndex >= 0 && subcategoryReport(groupByLocation ?
            [(report => report.location["@id"] === computedReport[reportLineIndex].location["@id"])] : []).find(report => report.subcategory?.["@id"] === computedReport[reportLineIndex].productReference.subcategory?.["@id"]);
        return (reportLineIndex >= 0 && (reportLineIndex === computedReport.length - 1 ||
            computedReport[reportLineIndex].productReference.subcategory?.["@id"] !== computedReport[reportLineIndex + 1].productReference.subcategory?.["@id"] ||
            (groupByLocation && computedReport[reportLineIndex].location["@id"] !== computedReport[reportLineIndex + 1].location["@id"]))) &&
            (<TableRow key={`subcategory-totals-${reportLineIndex}`}>
                {groupByLocation && <TableCell />}
                <TableCell />
                <TableCell><b>{translate("subcategory.subcategory")}</b></TableCell>
                <TableCell>{computedReport[reportLineIndex].productReference.subcategory?.name}</TableCell>
                {renderQuantitiesAndAmounts(reportLine)}
            </TableRow>);
    }

    const renderLocationTotals = (reportLineIndex, renderQuantitiesAndAmounts) => {
        if (!groupByLocation) {
            return;
        }
        const reportLine = reportLineIndex >= 0 && locationReport([]).find(report => report.location["@id"] === computedReport[reportLineIndex].location["@id"]);
        return (reportLineIndex >= 0 && (reportLineIndex === computedReport.length - 1 ||
            computedReport[reportLineIndex].location["@id"] !== computedReport[reportLineIndex + 1].location["@id"])) &&
            (<TableRow key={`location-${reportLineIndex}`}>
                <TableCell><b>{translate("location.location")}</b></TableCell>
                <TableCell />
                <TableCell />
                <TableCell>{computedReport[reportLineIndex].location.name}</TableCell>
                {renderQuantitiesAndAmounts(reportLine)}
            </TableRow>);
    }

    const renderTotals = (reportLineIndex, renderQuantitiesAndAmounts) => {
        const reportLine = reportLineIndex >= 0 && totalReport([])[0];
        return (reportLineIndex >= 0 && reportLineIndex === computedReport.length - 1 &&
            (<TableRow key={`total-${reportLineIndex}`}>
                <TableCell><b>{translate("report.total")}</b></TableCell>
                {groupByLocation && <TableCell />}
                <TableCell />
                <TableCell />
                {renderQuantitiesAndAmounts(reportLine)}
            </TableRow>))
    }

    const renderAllTotals = (reportLineIndex, renderQuantitiesAndAmounts) => {
        return (<>
            {renderSubcategoryTotals(reportLineIndex, renderQuantitiesAndAmounts)}
            {renderCategoryTotals(reportLineIndex, renderQuantitiesAndAmounts)}
            {renderLocationTotals(reportLineIndex, renderQuantitiesAndAmounts)}
            {renderTotals(reportLineIndex, renderQuantitiesAndAmounts)}
        </>);
    }

    const renderUsageReport = () => (
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    {groupByLocation && <TableCell />}
                    <TableCell />
                    <TableCell />
                    <TableCell>
                        {translate("product.product")}
                    </TableCell>
                    <TableCell>
                        {translate("general.beginning")}
                    </TableCell>
                    <TableCell>
                        {translate("report.beginningValue")}
                    </TableCell>
                    <TableCell>
                        {translate("report.invoiced")}
                    </TableCell>
                    <TableCell>
                        {translate("report.invoicedValue")}
                    </TableCell>
                    <TableCell>
                        {translate("general.end")}
                    </TableCell>
                    <TableCell>
                        {translate("report.endingValue")}
                    </TableCell>
                    <TableCell>
                        {translate("report.used")}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.used")} (L / kg)`}
                    </TableCell>
                    <TableCell>
                        {translate("report.usageCost")}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {computedReport &&
                    computedReport.map((reportLine, reportLineIndex) =>
                        <React.Fragment key={reportLineIndex}>
                            <TableRow>
                                {groupByLocation && <TableCell />}
                                <TableCell />
                                <TableCell />
                                <TableCell>
                                    {reportLine.productReference.name}
                                </TableCell>
                                {renderUsageQuantitiesAndAmounts(reportLine)}
                            </TableRow>
                            {renderAllTotals(reportLineIndex, renderUsageQuantitiesAndAmounts)}
                        </React.Fragment>)
                }
            </TableBody>
        </Table>

    );

    const renderVarianceReport = () => (
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    {groupByLocation && <TableCell />}
                    <TableCell />
                    <TableCell />
                    <TableCell>
                        {translate("product.product")}
                    </TableCell>
                    <TableCell>
                        {translate("report.sold")}
                    </TableCell>
                    <TableCell>
                        {translate("report.cogs")}
                    </TableCell>
                    <TableCell>
                        {translate("report.usageCost")}
                    </TableCell>
                    <TableCell>
                        {translate("report.variance")}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {computedReport &&
                    computedReport.map((reportLine, reportLineIndex) =>
                        <React.Fragment key={reportLineIndex}>
                            <TableRow>
                                {groupByLocation && <TableCell />}
                                <TableCell />
                                <TableCell />
                                <TableCell>
                                    {reportLine.productReference.name}
                                </TableCell>
                                {renderVarianceQuantitiesAndAmounts(reportLine)}
                            </TableRow>
                            {renderAllTotals(reportLineIndex, renderVarianceQuantitiesAndAmounts)}
                        </React.Fragment>)
                }
            </TableBody>
        </Table>

    );

    const renderPourCostReport = () => (
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    {groupByLocation && <TableCell />}
                    <TableCell />
                    <TableCell />
                    <TableCell>
                        {translate("product.product")}
                    </TableCell>
                    <TableCell>
                        {translate("report.numberSold")}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.sold")} ($)`}
                    </TableCell>
                    <TableCell>
                        {translate("report.cogs")}
                    </TableCell>
                    <TableCell>
                        {translate("report.usageCost")}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.sold")} (L)`}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.used")} (L)`}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.pourCost")} (%)`}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.idealPourCost")} (%)`}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.variance")} (%)`}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {computedReport &&
                    computedReport.map((reportLine, reportLineIndex) =>
                        <React.Fragment key={reportLineIndex}>
                            <TableRow>
                                {groupByLocation && <TableCell />}
                                <TableCell />
                                <TableCell />
                                <TableCell>
                                    {reportLine.productReference.name}
                                </TableCell>
                                {renderPourCostQuantitiesAndAmounts(reportLine)}
                            </TableRow>
                            {renderAllTotals(reportLineIndex, renderPourCostQuantitiesAndAmounts)}
                        </React.Fragment>)
                }
            </TableBody>
        </Table>

    );

    const renderStockReport = () => (
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    {groupByLocation && <TableCell />}
                    <TableCell />
                    <TableCell />
                    <TableCell>
                        {translate("product.product")}
                    </TableCell>
                    <TableCell>
                        {translate("general.beginning")}
                    </TableCell>
                    <TableCell>
                        {translate("report.beginningValue")}
                    </TableCell>
                    <TableCell>
                        {translate("report.invoiced")}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.sold")}`}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.sold")} ($)`}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.cogs")} ($)`}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.sold")} (L / kg)`}
                    </TableCell>
                    <TableCell>
                        {translate("report.stock")}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.stock")} ($)`}
                    </TableCell>
                    <TableCell>
                        {`${translate("report.stock")} (L / kg)`}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {computedReport &&
                    computedReport.map((reportLine, reportLineIndex) =>
                        <React.Fragment key={reportLineIndex}>
                            <TableRow>
                                {groupByLocation && <TableCell />}
                                <TableCell />
                                <TableCell />
                                <TableCell>
                                    {reportLine.productReference.name}
                                </TableCell>
                                {renderStockQuantitiesAndAmounts(reportLine)}
                            </TableRow>
                            {renderAllTotals(reportLineIndex, renderStockQuantitiesAndAmounts)}
                        </React.Fragment>)
                }
            </TableBody>
        </Table>

    );

    const reportTypes = [{ name: "report.usage", render: renderUsageReport },
    { name: "report.variance", render: renderVarianceReport },
    { name: "report.pourCost", render: renderPourCostReport },
    { name: "report.stock", render: renderStockReport }];

    const renderReportTypeSelector = () => {
        return (<FormControl className={classes.formControl}>
            <InputLabel id="mutiple-checkbox-label">{`${translate("report.reportType")}`}</InputLabel>
            <Select
                labelId="report-type-selector-label"
                id="report-type-selector"
                value={reportTypeIndex}
                onChange={(event) => { setReportTypeIndex(event.target.value); }}
                input={<Input />}
                className={classes.multiSelect}
            >
                {reportTypes.map((reportType, index) => (
                    <MenuItem key={index} value={index}>
                        <ListItemText primary={translate(reportType.name)} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>)
    }

    return (
        <TitledLayout title={translate("report.reports")}>
            <div className={classes.root}>
                {renderReportTypeSelector()}
                <div className={classes.startEndRow}>
                    <ReportInventoriesSelector
                        startingInventory={startingInventory}
                        endingInventory={endingInventory}
                        groupByLocation={groupByLocation}
                        selectedInvoices={selectedInvoices}
                        selectedDiscountAndComps={selectedDiscountAndComps}
                        sales={sales}
                        setStartingInventory={setStartingInventory}
                        setEndingInventory={setEndingInventory}
                        setGroupByLocation={setGroupByLocation}
                        setSelectedInvoices={setSelectedInvoices}
                        setSelectedDiscountAndComps={setSelectedDiscountAndComps}
                        setSales={setSales}
                        startingInventoryOnly={startingInventoryOnly}
                    />
                </div>
                {renderFilters()}
                {reportTypes[reportTypeIndex].render()}
            </div>
        </TitledLayout>
    )
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
        subcategories: state.resources["/subcategories"],
        locations: state.resources["/locations"],
        standardInventories: state.resources["/inventories"]?.filter(inventory => inventory.type === "STANDARD" || inventory.type === "INITIAL_STOCK"),
        invoices: state.resources["/intermediates"]?.filter(inventory => inventory.type === "INVOICE"),
        discountAndComps: state.resources["/intermediates"]?.filter(inventory => inventory.type === "DISCOUNT" || inventory.type === "COMP"),
        loading: state.resources.loading,
        error: state.resources.error,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearFilters: () => dispatch(clearFilters("productReferences")),
    listSubcategories: () => dispatch(list("/subcategories")),
    listLocations: () => dispatch(list("/locations")),
});
export default connect(mapStateToProps, mapDispatchToProps)(Reports);

import { Link, Dialog, DialogActions, Button, DialogContent, DialogTitle, makeStyles, FormControl, TextField, FormLabel } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router";
import AuthLayout from "../../../theme/auth/AuthLayout";
import ResetPassForm from "../../auth/ResetPassForm";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import { checkPassRequest } from "../../../actions/session";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    resetPassForm: {
        backgroundColor: "#202020",
        width: "60%",
        maxWidth: 700,
        padding: "60px 80px 60px 80px",
        boxShadow: "10px 5px black",
        borderRadius: 10,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        rowGap: 30,
    },
    textField: {
        minWidth: 300,
    },
    confirmationCodeDialog: {
        padding: 20,
    },
    dialogLink: {
        marginTop: 20,
    },
}));

function ResetPasswordPage(props) {
    const classes = useStyles();
    const history = useHistory();

    const { translate, checkPassRequest } = props;

    const [confimationCodeDialogVisible, setConfimationCodeDialogVisible] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState("");
    const [confirmationCodeError, setConfirmationCodeError] = useState();
    const [password, setPassword] = useState("");
    const [confirmationPassword, setConfirmationPassword] = useState("");
    const [passwordError, setPasswordError] = useState();

    const handleConfirmationCodeSubmit = () => {
        if((password?.length ?? 0) < 8 || (confirmationPassword?.length ?? 0) < 8 || (password?.length ?? 0) > 64 || (confirmationPassword?.length ?? 0) > 64) {
            setPasswordError({ text: translate("validation.passwordInvalid") });
            return;
        }
        if(password !== confirmationPassword) {
            setPasswordError({ text: translate("user.unmatchingPasswords") });
            return;
        }

        checkPassRequest({
            selector: confirmationCode,
            password: password,
        }).then((res) => {
            if(res.ok) {
                setConfimationCodeDialogVisible(false);
                history.push("/login");
            } else {
                setConfirmationCodeError({ text: translate("user.confirmationCodeError") });
            }
        }).catch((err) => {
            
        });
    }

    const renderConfirmationCodeDialog = () => {
        return (<Dialog
            open={confimationCodeDialogVisible}
            onClose={() => setConfimationCodeDialogVisible(false)}
            className={classes.confirmationCodeDialog}
            maxWidth={'lg'}
        >
            <DialogTitle id="form-dialog-title">
                {translate("user.enterConfirmationCode")}
            </DialogTitle>
            <DialogContent className={classes.content}>
                <FormControl>
                    <TextField
                        id="confirmation-code-textfield"
                        name="confirmation-code"
                        className={classes.textField}
                        label={translate("user.enterConfirmationCode")}
                        required
                        value={confirmationCode}
                        onChange={(event) => { setConfirmationCode(event.target.value) }}
                        error={confirmationCodeError}
                        helperText={confirmationCodeError?.text} />
                </FormControl>
                <FormControl>
                    <TextField
                        id="new-password-textfield"
                        name="new-password"
                        type="password"
                        className={classes.textField}
                        label={translate("user.password")}
                        required
                        value={password}
                        onChange={(event) => { setPassword(event.target.value) }}
                        error={passwordError}
                        helperText={passwordError?.text} />
                </FormControl>
                <FormControl>
                    <TextField
                        id="new-password-textfield"
                        name="new-password"
                        type="password"
                        className={classes.textField}
                        label={translate("user.confirmPassword")}
                        required
                        value={confirmationPassword}
                        onChange={(event) => { setConfirmationPassword(event.target.value) }}
                        error={passwordError}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfimationCodeDialogVisible(false)} color="secondary">
                    {translate("button.cancel")}
                </Button>
                <Button onClick={handleConfirmationCodeSubmit} color="primary">
                    {translate("button.save")}
                </Button>
            </DialogActions>
        </Dialog>)

    }

    return (
        <AuthLayout className={classes.root}>
            {renderConfirmationCodeDialog()}
            <div className={classes.resetPassForm}>
                <ResetPassForm
                    onSuccess={() => setConfimationCodeDialogVisible(true)}
                    onCancel={() => history.push("/login")}
                />
                <FormLabel className={classes.dialogLink}>
                  <Link href="#" onClick={() => setConfimationCodeDialogVisible(true)}>{translate("user.alreadyHaveConfirmationCode")}</Link>
                </FormLabel>
            </div>
        </AuthLayout>
    )
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({
    checkPassRequest: ({ selector, password }) => dispatch(checkPassRequest({ selector, password })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);

const initialState = {};

export default function filters(state = initialState, action) {
    switch(action.type) {
        case "ADD_FILTERS":
            return {
                ...state,
                [action.value.label]: state.label ?
                    { ...state.label, ...action.value.filters } :
                    { ...action.value.filters },
            };
        case "CLEAR_FILTERS":
            return {
                ...state,
                [action.value]: undefined,
            };
        default:
            return state;
    }
}
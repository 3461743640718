const global = {
  general: {
    name: ["Name", "Nom"],
    description: ["Description", "Description"],
    quantity: ["Quantity", "Quantité"],
    search: ["Search", "Rechercher"],
    unit: ["Unit", "Unité"],
    notes: ["Notes", "Notes"],
    type: ["Type", "Type"],
    date: ["Date", "Date"],
    id: ["ID", "ID"],
    start: ["Start", "Début"],
    end: ["End", "Fin"],
    beginning: ["Beginning", "Début"],
    catalog: ["Catalog", "Catalogue"],
  },
  importer: {
    startingRow: ["Starting row", "Lignes d'en-tête"],
    csvImport: ["CSV Import", "Import CSV"],
  },
  button: {
    submit: ["Submit", "Valider"],
    signin: ["Sign In", "Se Connecter"],
    save: ["Save", "Sauvegarder"],
    create: ["Create", "Créer"],
    delete: ["Delete", "Supprimer"],
    cancel: ["Cancel", "Annuler"],
  },
  welcome: {
    welcome: ["Welcome", "Bienvenue"],
    language: ["Language", "Langage"],
    aboutUs: ["About us", "Qui sommes nous"],
  },
  validation: {
    emailInvalid: [
      "Please enter a valid email.",
      "Veuillez entrer un email valide.",
    ],
    passwordInvalid: [
      "Please enter a valid password (between 8 and 64 signs).",
      "Veuillez entrer un mot de passe entre 8 et 64 caractères.",
    ],
    fieldInvalid: ["Invalid field", "Champ invalide"],
  },
  user: {
    email: ["Email", "Email"],
    username: ["Username", "Nom d'utilisateur"],
    password: ["Password", "Mot de passe"],
    confirmPassword: ["Confirm Password", "Confirmer le mot de passe"],
    firstname: ["First Name", "Prénom"],
    lastname: ["Last Name", "Nom de famille"],
    rememberMe: ["Keep Signed-in", "Rester connecté"],
    invalidCredentials: ["Invalid credentials", "Ces identifiants n'ont pas été reconnus"],
    resetPassword: ["Reset password", "Mot de passe oublié"],
    profile: ["Profile", "Profil"],
    logout: ["Logout", "Déconnexion"],
    enterConfirmationCode: ["Enter Confirmation Code", "Entrez le code de confirmation"],
    unmatchingPasswords: ["Provided passwords are different", "Les mots de passes fournis sont différents"],
    confirmationCodeError: ["The confirmation code could not be validated, please try again.", "Le code n'a pû être vérifié. Veuillez réessayer."],
    alreadyHaveConfirmationCode: ["You already have a confirmation code ?", "Vous avez déjà un code confirmation ?"],
  },
  language: {
    french: ["French", "Français"],
    english: ["English", "Anglais"],
  },
  error: {
    unknown: ["An error has occured...", "Une erreur est survenue..."],
  },
  product: {
    products: ["Products", "Produits"],
    product: ["Product", "Produit"],
  },
  recipe: {
    recipes: ["Recipes", "Consommations"],
    recipe: ["Recipe", "Consommation"],
    posId: ["POS ID", "ID POS"],
    ingredients: ["Ingredients", "Ingrédients"],
    cost: ["Cost", "Coût"],
  },
  category: {
    categories: ["Categories", "Catégories"],
    category: ["Category", "Catégorie"],
  },
  vendor: {
    vendors: ["Vendors", "Vendeurs"],
    vendor: ["Vendor", "Vendor"],
  },
  location: {
    locations: ["Locations", "Emplacements"],
    location: ["Location", "Emplacement"],
  },
  productGroup: {
    productGroups: ["Product Groups", "Groupes produit"],
    productGroup: ["Product Group", "Groupe produit"],
  },
  productReference: {
    productReferences: ["References", "Reférences"],
    productReference: ["Reference", "Réference"],
    capacity: ["Capacity", "Capacité"],
    capacityUnit: ["Unit", "Unité"],
    doseCapacity: ["Dose Capacity", "Capacité dose"],
    doseCapacityUnit: ["Unit", "Unité"],
    doseCapacityUnitLong: ["Dose Unit", "Unité dose"],
    productCode: ["Code", "Code"],
    empty: ["Empty weight", "Poids vide"],
    full: ["Full weight", "Poids plein"],
  },
  subcategory: {
    subcategories: ["Subcategories", "Sous-catégories"],
    subcategory: ["Subcategory", "Sous-catégorie"],
  },
  inventory: {
    inventories: ["Inventories", "Inventaires"],
    inventory: ["Inventory", "Inventaire"],
    inventories_and_sales: ["Inventories & Sales", "Inventaires & Ventes"],
    standard: ["Standard", "Standard"],
    discount: ["Discount", "Promo"],
    comp: ["Comp", "Casse"],
    caseSize: ["Case size", "Taille de caisse"],
    initialStock: ["Initial stock", "Stock initial"],
    inventoryItems: ["", ""],
  },
  invoice: {
    invoices: ["Invoices", "Factures"],
    invoice: ["Invoice", "Facture"],
    amount: ["Amount", "Montant"],
    invoiceNumber: ["Invoice n°", "N° Facture"],
    uploadMetroPdf: ["Upload Metro Invoice (PDF)", "Uploader Facture Metro (PDF)"],
  },
  sale: {
    sales: ["Sales", "Ventes"],
    amount: ["Amount", "Montant"],
    registerId: ["Register ID", "ID caisse"],
    numberSold: ["Number sold", "Nombre vendu"],
  },
  unit: {
    cl: ["cl", "cl"],
    ml: ["ml", "ml"],
    L: ["L", "L"],
    kg: ["kg", "kg"],
    g: ["g", "g"],
  },
  unitType: {
    unitType: ["Unit type", "Type d'unités"],
    bottle: ["Bottle", "Bouteille"],
    content: ["Weight / Volume", "Poids / Volume"],
    case: ["Case", "Caisse"],
    food: ["Food", "Nourriture"],
  },
  warnings: {
    delete_resource: ["This action cannot be undone, are you sure you want to proceed ?", "Cette action est irréversible, êtes vous sûrs de vouloir continuer"],
  },
  report: {
    reports: ["Reports", "Rapports"],
    reportType: ["Report Types", "Type de rapports"],
    usage: ["Usage", "Usage"],
    stock: ["Stock", "Stock"],
    startingInventory: ["Starting Inventory", "Inventaire de début"],
    endingInventory: ["Ending Inventory", "Inventaire de fin"],
    groupByLocation: ["Group by location", "Grouper par emplacement"],
    beginningValue: ["Beginning Value", "Valeur initiale"],
    endingValue: ["Ending Value", "Valeur finale"],
    invoiced: ["Invoiced", "Facturé"],
    invoicedValue: ["Invoiced Value", "Montant facturé"],
    sold: ["Sold", "Vendu"],
    used: ["Used", "Utilisé"],
    usageCost: ["Usage Cost", "Coût d'usage"],
    cogs: ["COGS", "COGS"],
    variance: ["Variance", "Variance"],
    poured: ["Poured (L/kg)", "Versé (L/kg)"],
    total: ["Total", "Total"],
    numberSold: ["Number Sold", "Nombre Vendu"],
    pourCost: ["Pour Cost", "Coût versé"],
    idealPourCost: ["Ideal Pour Cost", "Coût Versé idéal"],
  },
};

export default global;

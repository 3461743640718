import { getUnitInLiterKilo } from "./units";

export const getQuantity = (inventoryItem) => {
    switch (inventoryItem.unitType) {
        case "CASE":
            return inventoryItem.caseSize * inventoryItem.quantity;
        case "CONTENT":
            const { full = 0, empty = 0 } = inventoryItem.productReference;
            return (inventoryItem.quantity - empty) / ((full - empty) > 0 ? (full - empty) : 1);
        default:
            return inventoryItem.quantity;
    }
}

export const getProductReferenceUnitInLiterKilo = (productReference) => {
    return getUnitInLiterKilo(productReference.capacityUnit);
}

export const getInventoryItemQuantityInLiterKilo = (inventoryItem) => {
    return getQuantity(inventoryItem)
        * inventoryItem.productReference.capacity
        * getProductReferenceUnitInLiterKilo(inventoryItem.productReference);
}

export const getIngredientQuantityInLiterKilo = (ingredient) => {
    return (ingredient.quantity && ingredient.unit) ?
        ingredient.quantity * getUnitInLiterKilo(ingredient.unit) :
        0;
}
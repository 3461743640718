import React from "react";
import ResourceAutocomplete from "../components/utils/ResourceAutocomplete";
import unitTypes, { parseLabel as parseUnitTypeLabel } from "./unitTypes"

const inventoryItems = [
    {
        type: "custom",
        name: "productReference",
        rules: [],
        getLabel: (productReference) => productReference?.name,
        label: "productReference.productReference",
        customComponent: (props) => (
            <ResourceAutocomplete
                {...props}
                resource="/product_references"
                onChange={(event, newValue) => props.onChange({ target: { value: newValue } })}
                listingToOptions={listing => listing.map(l => l["@id"])}
                getOptionLabel={(option, listing) => {console.log("option", option, listing);return listing?.find(l => l["@id"] === option)?.name;}}
            />),
        getValue: (inventoryItem) => inventoryItem?.productReference?.["@id"],
        required: true,
    },
    {
        type: "dropdown",
        name: "location",
        rules: [],
        getLabel: (location) => location?.name,
        dropdownResource: "/locations",
        dropdownResourceView: (location) => location.name,
        label: "location.location",
        required: true,
    },
    {
        type: "text",
        name: "quantity",
        rules: [{ rule: "numeric|min:0,num", message: "validation.fieldInvalid" }],
        label: "general.quantity",
        numeric: true,
        required: true,
    },
    {
        type: "select",
        name: "unitType",
        rules: [],
        getLabel: parseUnitTypeLabel,
        label: "unitType.unitType",
        options: unitTypes,
        required: true,
    },
    {
        type: "text",
        name: "caseSize",
        rules: [{ rule: "numeric|integer|min:0,num", message: "validation.fieldInvalid" }],
        label: "inventory.caseSize",
        hiddenCondition: (inventoryItem) => inventoryItem.unitType !== "CASE",
        numeric: true,
    },
    {
        type: "text",
        name: "amount",
        rules: [{ rule: "numeric|min:0,num", message: "validation.fieldInvalid" }],
        label: "invoice.amount",
        numeric: true,
        noEdit: true,
    },
];

export default inventoryItems;
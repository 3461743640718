import { PREFIX } from "../config/entrypoint";

export function removePrefix(str) {
    if(!PREFIX || PREFIX.length === 0) {
        return str;
    }

    const reducedPrefix = PREFIX.startsWith("/") ? PREFIX.slice(1) : PREFIX;
    if(str.startsWith(reducedPrefix)) {
        return str.slice(PREFIX.length);
    }

    if(str.startsWith("/" + reducedPrefix)) {
        return str.slice(PREFIX.length + 1);
    }
    return str;
}

export function addPrefix(str) {
    if(!PREFIX || PREFIX.length === 0) {
        return str;
    }
    
    const reducedPrefix = PREFIX.startsWith("/") ? PREFIX.slice(1) : PREFIX;
    return "/" + reducedPrefix + (str.startsWith("/") ? removePrefix(str) : ("/" + removePrefix(str)));
}

export function getResourceFromId(id) {
    let res = id?.split("/");
    if (!res) {
        return undefined;
    }
    res.pop();
    res = res.join("/");

    return removePrefix(res);
}
import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Translate } from "react-localize-redux";
import { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { login } from "../../actions/session";
import { FormLabel } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    display: "flex",
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: 400,
  },
  textInput: {
    display: "flex",
    width: "100%",
  },
  signinButton: {
    display: "flex",
    width: "100%",
    height: 50,
  },
  formGroup: {
    alignSelf: "flex-start",
    color: "#12aaeb",
  },
  formLabel: {
    color: "#12aaeb",
  },
  loginError: {
    marginTop: 10,
    color: "red",
  },
}));

function LoginForm(props) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginError, setLoginError] = useState();
  const classes = useStyles();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };
  const handleRemberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const validate = () => {
    const validator = new SimpleReactValidator();
    let res = true;
    if (!validator.check(email, "required|email")) {
      setEmailError(true);
      res = false;
    }
    if (!validator.check(password, "required|min:8|max:64")) {
      setPasswordError(true);
      res = false;
    }

    return res;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoginError(undefined);
    if (validate()) {
      props
        .login({ email: email, password, rememberMe })
        .then(() => {
          if (props.onAuthenticationSuccess) {
            props.onAuthenticationSuccess();
          }
        })
        .catch((error) => {
          setLoginError(error.message);
        })
        .then(() => {
          if (props.onSubmit) {
            props.onSubmit();
          }
        });
    }
  };

  const renderLoginError = () => (
    <Translate>
      {({ translate }) => (
        <p className={classes.loginError}>
          {Number.parseInt(loginError) === 401
            ? translate("user.invalidCredentials")
            : translate("error.unknown")}
        </p>
      )}
    </Translate>
  );

  return (
    <div className={classes.root}>
      <Translate>
        {({ translate }) => {
          return (
            <form className={classes.form} onSubmit={onSubmit}>
              <TextField
                className={classes.textInput}
                variant="outlined"
                required
                type="email"
                id="email"
                label={translate("user.email")}
                placeholder={translate("user.email")}
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError && translate("validation.emailInvalid")}
              />
              <TextField
                className={classes.textInput}
                variant="outlined"
                required
                type="password"
                id="password"
                label={translate("user.password")}
                placeholder={translate("user.password")}
                value={password}
                onChange={handlePasswordChange}
                error={passwordError}
                helperText={
                  passwordError && translate("validation.passwordInvalid")
                }
              />
              <FormGroup row className={classes.formGroup}>
                <FormLabel className={classes.formLabel}>
                  <Link to="resetpass">{translate("user.resetPassword")}</Link>
                </FormLabel>
              </FormGroup>
              <FormGroup row className={classes.formGroup}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={handleRemberMeChange}
                      name="rememberMe"
                      color="primary"
                    />
                  }
                  label={translate("user.rememberMe")}
                />
              </FormGroup>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.signinButton}
              >
                {translate("button.signin")}
              </Button>
              {loginError && renderLoginError()}
            </form>
          );
        }}
      </Translate>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: ({ email, password, rememberMe }) =>
    dispatch(login({ email, password, rememberMe })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import MainLayout from "./MainLayout";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    mainContainer: {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
    },
    title: {
        backgroundColor: "#303030",
        padding: 10,
        paddingLeft: 20,
        borderRadius: 5,
    },
    titleContainer: {
        width: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        borderColor: "#404040",
        borderStyle: "dotted",
        borderWidth: 1,
        borderRadius: 5,
    },
    contentContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: 30,
    }
}));

function TitledLayout(props) {
    const classes = useStyles();

    return (
        <MainLayout>
            <div className={classes.root}>
            <div className={classes.mainContainer}>
                <div className={classes.titleContainer}>
                    <Typography className={classes.title} variant="h5">
                        {props.title}
                    </Typography>
                </div>
                <div className={classes.contentContainer}>
                    {props.children}
                </div>
            </div>
            </div>
        </MainLayout>
    )
}

export default TitledLayout;
import React from "react";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { addFilters, clearFilters } from "../actions/filters";

function SubcategoryLink(props) {
    const { item, addFilters } = props;
    const history = useHistory();

    const handleClick = (event) => {
        event.preventDefault();
        addFilters({ subcategory: item["@id"] });
        history.push("/product_references");
    }

    return (
        <Link href={"/product_references"} onClick={handleClick} style={{color: "whitesmoke"}}>
            {item.name}
        </Link>
    )

}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearFilters: () => dispatch(clearFilters("productReferences")),
    addFilters: (filters) => dispatch(addFilters("productReferences", filters)),
});

export const ConnectedSubcategoryLink = connect(mapStateToProps, mapDispatchToProps)(SubcategoryLink);


const subcategories = [
    {
        type: "dropdown",
        name: "category",
        rules: [{rule: "required", message: "validation.fieldInvalid"}],
        label: "category.category",
        dropdownResource: "/categories",
        dropdownResourceView: (category) => category["name"],
    },
    {
        type: "text",
        name: "name",
        rules: [{rule: "required", message: "validation.fieldInvalid"}],
        label: "general.name",
        CustomComponent: ConnectedSubcategoryLink,
    },
];

export default subcategories;
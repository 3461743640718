
import { removePrefix } from "../utils/resources";

const initialState = {
    loading: false,
    loadings: [],
    error: null,
};

export default function resources(state = initialState, action) {


    switch (action.type) {
        case "CREATE_RESOURCE_LOADING": case "LIST_RESOURCE_LOADING": case "UPDATE_RESOURCE_LOADING": case "REMOVE_RESOURCE_LOADING":
            return {
                ...state,
                loading: true,
                loadings: [...state.loadings, action.value],
                error: null,
            };
        case "RESOURCE_ERROR":
            return {
                ...state,
                loading: false,
                error: state.value,
            };
        case "CREATE_RESOURCE":
            return {
                ...state,
                [action.value.resource]: [...state[action.value.resource], action.value.created],
                created: action.value.created,
                loading: false,
                error: null,
            };
        case "LIST_RESOURCE":
            const resourceN = removePrefix(action.name ?? action.value["@id"]);
            return {
                ...state,
                [resourceN]: action.value["hydra:member"],
                loadings: state.loadings.filter(ld => ld !== resourceN),
                loading: false,
                error: null,
            };
        case "GET_BY_ID_RESOURCE":
            let resourceName = action.value["@id"].split("/");
            resourceName.pop();
            resourceName = removePrefix(resourceName.join("/"));
            const resourceArray = state?.[resourceName]?.filter(res => res["@id"] !== action.value["@id"]) ?? [];
            return {
                ...state,
                [resourceName]: [...resourceArray, action.value],
                loading: false,
                error: null,
            };
        default:
            return state;
    }
}
const units = [{ value: "MILLILITERS", label: "unit.ml" },
{ value: "CENTILITERS", label: "unit.cl" },
{ value: "LITERS", label: "unit.L" },
{ value: "KILOGRAMS", label: "unit.kg" },
{ value: "GRAMS", label: "unit.g" },
];

export default units;

export const parseLabel = (unit, translate) => {
    const label = units.find(u => unit === u.value)?.label;
    return label && translate ? translate(label) : label;
};
import moment from "moment";
import inventoryTypes, { parseLabel as parseInventoryTypeLabel } from "./inventoryTypes"

const invoices = [
    {
        type: "text",
        name: "@id",
        rules: [{ rule: "required", message: "validation.fieldInvalid" }],
        label: "general.id",
        getLabel: (id) => id?.split("/")?.pop(),
        required: true,
        noEdit: true,
    },
    {
        type: "text",
        name: "invoiceNumber",
        rules: [],
        label: "invoice.invoiceNumber",
        disableCondition: (values) => values.type !== "INVOICE",
    },
    {
        type: "text",
        name: "description",
        rules: [{ rule: "required", message: "validation.fieldInvalid" }],
        label: "general.description",
    },
    {
        type: "text",
        name: "notes",
        rules: [],
        label: "general.notes",
    },
    {
        type: "select",
        name: "type",
        rules: [{ rule: "required", message: "validation.fieldInvalid" }],
        getLabel: parseInventoryTypeLabel,
        label: "general.type",
        options: inventoryTypes.filter(iT => iT.value === "INVOICE"),
        required: true,
    },
    {
        type: "datetime",
        name: "date",
        rules: [{ rule: "required", message: "validation.fieldInvalid" }],
        label: "general.date",
        getLabel: (item) => moment(item).format("DD-MM-YYYY HH:mm"),
    },
    {
        type: "dropdown",
        name: "vendor",
        rules: [],
        getLabel: (vendor) => vendor?.name,
        dropdownResource: "/vendors",
        dropdownResourceView: (vendor) => vendor.name,
        label: "vendor.vendor",
        required: true,
    },
];

export default invoices;
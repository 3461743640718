import React from "react";
import { Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ConnectedRouter } from "connected-react-router";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
// Import your reducers and routes here
import Welcome from "./Welcome";
import WelcomePage from "./components/pages/Welcome";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "./translations/global";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { LocalizeProvider, localizeReducer } from "react-localize-redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from 'redux-persist/integration/react';
// Import your reducers and routes here
import session from "./reducers/session";
import filters from "./reducers/filters";
import Logout from "./components/auth/Logout";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme/theme"
import LoginPage from "./components/pages/auth/LoginPage";
import AuthenticatedRoute from "./components/middleware/AuthenticatedRoute";
import Categories from "./components/pages/Categories";
import Vendors from "./components/pages/Vendors";
import resources from "./reducers/resources";
import Locations from "./components/pages/Locations";
import ProductGroups from "./components/pages/ProductGroups";
import ProductReferences from "./components/pages/ProductReferences";
import Recipes from "./components/pages/Recipes";
import Inventories from "./components/pages/Inventories";
import InventoriesForm from "./components/pages/InventoriesForm";
import Sales from "./components/pages/Sales";
import ProductReferencesImporter from "./components/importers/ProductReferencesImporter";
import Reports from "./components/pages/Reports";
import Profile from "./components/pages/Profile";
import ResetPasswordPage from "./components/pages/auth/ResetPasswordPage";

const persistConfig = {
  key: "root",
  storage,
};

const persistConfigLocalize = {
  key: "localize",
  storage,
};

const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    session: persistReducer(persistConfig, session),
    resources: resources,
    filters: filters,
    localize: persistReducer(persistConfigLocalize, localizeReducer),
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

let persistor = persistStore(store);

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizeProvider
          initialize={{
            languages: [
              { name: "English", code: "en" },
              { name: "French", code: "fr" },
            ],
            translation: globalTranslations,
            options: { renderToStaticMarkup },
          }}
          store={store}
        >
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
              <Switch>
                <AuthenticatedRoute path="/" component={WelcomePage} strict={true} exact={true} />
                <AuthenticatedRoute
                  path="/apihome"
                  component={Welcome}
                  strict={true}
                  exact={true}
                />
                <Route
                  path="/login"
                  component={() => (<LoginPage />)}
                  strict={true}
                  exact={true}
                />
                <Route
                  path="/resetpass"
                  component={() => (<ResetPasswordPage />)}
                  strict={true}
                  exact={true}
                />
                <Route
                  path="/logout"
                  component={() => (<Logout />)}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/categories"
                  component={() => (<Categories />)}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/vendors"
                  component={() => (<Vendors />)}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/locations"
                  component={() => (<Locations />)}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/product_groups"
                  component={() => (<ProductGroups />)}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/product_references"
                  component={() => (<ProductReferences />)}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/recipes"
                  component={() => (<Recipes />)}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/inventories"
                  component={() => (<Inventories />)}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/inventories/:id"
                  component={InventoriesForm}
                />
                <AuthenticatedRoute
                  path="/invoices"
                  component={() => (<Inventories invoicesOnly={true} />)}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/invoices/:id"
                  component={() => (<InventoriesForm invoicesOnly={true} />)}
                />
                <AuthenticatedRoute
                  path="/sales"
                  component={() => (<Sales invoicesOnly={true} />)}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/primport"
                  component={ProductReferencesImporter}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/reports"
                  component={Reports}
                  strict={true}
                  exact={true}
                />
                <AuthenticatedRoute
                  path="/profile"
                  component={Profile}
                  strict={true}
                  exact={true}
                />
                {/* Add your routes here */}
                {/*<Route render={() => <h1>Not Found</h1>} />*/}
              </Switch>
            </ConnectedRouter>
          </PersistGate>
        </LocalizeProvider>

      </Provider>
    </ThemeProvider>
  );
}

export default withLocalize(App);

import React, { useEffect, useState } from "react";
import { getTranslate } from "react-localize-redux";
import TitledLayout from "../../theme/TitledLayout";
import { list } from "../../actions/resources";
import { connect } from "react-redux";
import { Collapse, Container, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import EditableTableRow from "../utils/EditableTableRow";
import Paper from '@material-ui/core/Paper';
import categoriesDef, { ConnectedCategoryLink } from "../../definitions/categories";
import subcategoriesDef, { ConnectedSubcategoryLink } from "../../definitions/subcategories";
import ResourceModal from "../utils/ResourceModal";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ResourceDeleteDialog from "../utils/ResourceDeleteDialog";
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    tableContainer: {
        maxWidth: 500,
    },
    smallCell: {
        width: 20,
    },
    subcategoryCollapse: {
        padding: 10,
        paddingBottom: 30,
    },
}));


function Categories(props) {
    const classes = useStyles();
    const [dialogId, setDialogId] = useState();
    const [subcategoryDialogId, setSubcategoryDialogId] = useState();
    const [deleteDialogId, setDeleteDialogId] = useState();
    const [subcategoryDeleteDialogId, setSubcategoryDeleteDialogId] = useState();
    const [createDialogVisible, setCreateDialogVisible] = useState(false);
    const [subcategoryCreateDialogVisible, setSubcategoryCreateDialogVisible] = useState(false);
    const [subcategoryCreateDialogCategoryId, setSubcategoryCreateDialogCategoryId] = useState();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [subcategoryDialogVisible, setSubcategoryDialogVisible] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [subcategoryDeleteDialogVisible, setSubcategoryDeleteDialogVisible] = useState(false);
    const [openedAccordions, setOpenedAccordions] = useState({});
    const { translate } = props;

    useEffect(() => {
        if (!props.categories && !props.loading && props.error === null) {
            props.listCategories();
        }
        if (!props.subcategories && !props.loading && props.error === null) {
            props.listSubcategories();
        }
    });

    const handleCreate = () => {
        setCreateDialogVisible(true);
    };

    const handleSubcategoryCreate = (categoryId) => {
        setSubcategoryCreateDialogVisible(true);
        setSubcategoryCreateDialogCategoryId(categoryId);
    };

    const handleEdit = (id) => {
        setDialogId(id);
        setDialogVisible(true);
    };

    const handleSubcategoryEdit = (id) => {
        setSubcategoryDialogId(id);
        setSubcategoryDialogVisible(true);
    };

    const handleDelete = (id) => {
        setDeleteDialogId(id);
        setDeleteDialogVisible(true);
    };

    const handleSubcategoryDelete = (id) => {
        setSubcategoryDeleteDialogId(id);
        setSubcategoryDeleteDialogVisible(true);
    };

    const renderCreateDialog = () => {
        return (
            <ResourceModal
                title={translate("category.category")}
                open={createDialogVisible}
                onClose={() => {
                    setCreateDialogVisible(false);
                }}
                resource="/categories"
                data={categoriesDef}
            />);
    }

    const renderSubcategoryCreateDialog = () => {
        return (
            <ResourceModal
                title={translate("subcategory.subcategory")}
                open={subcategoryCreateDialogVisible}
                onClose={() => {
                    setSubcategoryCreateDialogVisible(false);
                }}
                data={subcategoriesDef}
                resource="/subcategories"
                defaultValues={{ category: subcategoryCreateDialogCategoryId }}
            />);
    }

    const renderEditDialog = () => {
        return (
            <ResourceModal
                title={translate("category.category")}
                resourceId={dialogId}
                open={dialogVisible}
                onClose={() => {
                    setDialogVisible(false);
                    setDialogId(undefined);
                }}
                data={categoriesDef}
            />);
    }

    const renderSubcategoryEditDialog = () => {
        return (
            <ResourceModal
                title={translate("subcategory.subcategory")}
                resourceId={subcategoryDialogId}
                open={subcategoryDialogVisible}
                onClose={() => {
                    setSubcategoryDialogVisible(false);
                    setSubcategoryDialogId(undefined);
                }}
                data={subcategoriesDef}
            />);
    }

    const renderDeleteDialog = () => {
        return (
            <ResourceDeleteDialog
                resourceId={deleteDialogId}
                open={deleteDialogVisible}
                onClose={() => {
                    setDeleteDialogVisible(false);
                    setDeleteDialogId(undefined);
                }}
            />);
    }

    const renderSubcategoryDeleteDialog = () => {
        return (
            <ResourceDeleteDialog
                resourceId={subcategoryDeleteDialogId}
                open={subcategoryDeleteDialogVisible}
                onClose={() => {
                    setSubcategoryDeleteDialogVisible(false);
                    setSubcategoryDeleteDialogId(undefined);
                }}
            />);
    }

    const handleAccordionsToggle = (categoryId) => {
        setOpenedAccordions({
            ...openedAccordions,
            [categoryId]: openedAccordions[categoryId] ? false : true,
        });
    };

    const renderCategoryRow = (category) =>
    (<EditableTableRow
        key={category["@id"]}
        onEdit={() => handleEdit(category["@id"])}
        onDelete={() => handleDelete(category["@id"])}
        resourceId={category["@id"]}
    >
        <TableCell className={classes.smallCell}>
            <IconButton aria-label="expand row" size="small" onClick={() => handleAccordionsToggle(category["@id"])}>
                {openedAccordions[category["@id"]] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </TableCell>
        <TableCell>
            <ConnectedCategoryLink item={category} />
        </TableCell>
    </EditableTableRow>)

    const renderSubcategoryRow = (subcategory) => {
        return (
            <EditableTableRow
                key={subcategory["@id"]}
                onEdit={() => handleSubcategoryEdit(subcategory["@id"])}
                onDelete={() => handleSubcategoryDelete(subcategory["@id"])}
                resourceId={subcategory["@id"]}
            >
                <TableCell>
                    <ConnectedSubcategoryLink item={subcategory} />
                </TableCell>
            </EditableTableRow>
        );
    }

    const renderSubcategories = (category) => {
        return (
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={openedAccordions[category["@id"]]} timeout="auto" unmountOnExit>
                        <div className={classes.subcategoryCollapse}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {translate("general.name")}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                aria-label="create"
                                                className={classes.smallIconButton}
                                                onClick={() => handleSubcategoryCreate(category["@id"])}
                                                >
                                                <AddCircleIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.subcategories
                                        .filter(subcategory => subcategory.category["@id"] === category["@id"])
                                        .map(subcategory => renderSubcategoryRow(subcategory))}
                                </TableBody>
                            </Table>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <TitledLayout title={translate("category.categories")}>
            {renderCreateDialog()}
            {renderSubcategoryCreateDialog()}
            {renderEditDialog()}
            {renderSubcategoryEditDialog()}
            {renderDeleteDialog()}
            {renderSubcategoryDeleteDialog()}
            <Container className={classes.root}>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>
                                    {translate("general.name")}
                                </TableCell>
                                <TableCell>
                                    <IconButton aria-label="create" className={classes.smallIconButton} onClick={handleCreate}>
                                        <AddCircleIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.categories && props.categories.map(category => {
                                return (
                                    <React.Fragment key={category["@id"]}>
                                        {renderCategoryRow(category)}
                                        {props.subcategories && renderSubcategories(category)}
                                    </React.Fragment>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </TitledLayout >);
}

const mapStateToProps = (state) => {
    return {
        loading: state.resources.loading,
        error: state.resources.error,
        subcategories: state.resources["/subcategories"],
        categories: state.resources["/categories"],
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({
    listCategories: () =>
        dispatch(list("/categories")),
    listSubcategories: () =>
        dispatch(list("/subcategories")),
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);

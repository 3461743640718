import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import EditableTableRow from "../utils/EditableTableRow";
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { list } from "../../actions/resources";
import SimpleReactValidator from "simple-react-validator";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
    },
    smallCell: {
        width: 20,
    },
    headCell: {
        display: "flex",
        flexDirection: "row"
    },
    headCellIcon: {
        paddingBottom: 3,
        width: 24,
        height: 24,
    },
}));


const CrudInput = forwardRef((props, ref) => {
    const classes = useStyles();
    const [errors, setFormErrors] = useState({});
    const { translate } = props;

    const items = props.value;

    const validate = () => {
        const validator = new SimpleReactValidator();
        let res = true;

        if (!items) {
            return res;
        }

        props.resourceDef.filter(defItem => !defItem.noEdit).forEach(defItem => {
            if (defItem.rules) {
                defItem.rules.forEach((rule) => {
                    items.forEach((values, index) => {
                        if (!validator.check(values[defItem.name], rule.rule)) {
                            setFormErrors({
                                ...errors,
                                [defItem.name]: {
                                    ...(errors?.defItem?.[index] ?? {}),
                                    [index]: (rule.message ? translate(rule.message) : translate("validation.fieldInvalid"))
                                }
                            });
                            res = false;
                        }
                    });
                });
            }
        });

        return res;
    };

    useEffect(() => {
        props.resourceDef.forEach((defItem) => {
            if ((defItem.dropdownResource && !props.resources[defItem.dropdownResource])) {
                props.list(defItem.dropdownResource);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref,
        () => ({ validate }));

    const handleCreate = () => {
        props.onChange({
            target: { value: items ? [...items, {}] : [{}] }
        });
    };

    const handleEdit = (value, item, index, defItem) => {
        let newItem = defItem.setValues ? defItem.setValues(value, item) : { ...item, [defItem.name]: value };
        props.onChange({
            target: {
                value: items.map((it, ind) => index === ind ? newItem : it)
            }
        });
    }

    const handleDelete = (id) => {
        props.onChange({
            target: { value: items.filter((_, index) => index !== id) }
        });
    };

    const renderCustomComponent = (item, index, defItem) => {
        const CustomComponent = defItem.customComponent;
        return (
            <FormControl key={defItem.name} className={classes.formControl}>
                {CustomComponent && <CustomComponent
                    id={defItem.name}
                    type={defItem.type}
                    name={defItem.name}
                    value={defItem.getValue ? defItem.getValue(item) : item?.[defItem.name]}
                    required={defItem.required ?? false}
                    label={defItem.label && translate(defItem.label)}
                    onChange={(event) => {
                        handleEdit(event.target.value, item, index, defItem);
                    }}
                    error={errors?.[defItem.name]?.[index] ? true : false}
                    helperText={errors?.[defItem.name]?.[index] ?? undefined} />}
            </FormControl>
        );
    };

    const renderTextField = (item, index, defItem) => {
        return (
            <FormControl key={defItem.name} className={classes.formControl}>
                <TextField
                    id={defItem.name}
                    type={defItem.type}
                    name={defItem.name}
                    className={classes.textField}
                    required={defItem.required ?? false}
                    label={defItem.label && translate(defItem.label)}
                    value={item[defItem.name]}
                    onChange={(event) => {
                        handleEdit(defItem.numeric ?
                            (isNaN(event.target.value) ?
                                event.target.value :
                                parseFloat(event.target.value)) :
                            event.target.value, item, index, defItem);
                    }}
                    error={errors?.[defItem.name]?.[index] ? true : false}
                    helperText={errors?.[defItem.name]?.[index] ?? undefined} />
            </FormControl>
        );
    };

    const renderResourceDropdown = (item, index, defItem) => {
        return (
            <FormControl key={defItem.name} className={classes.formControl}>
                {defItem.label && (<InputLabel className={classes.inputLabel} shrink>
                    {translate(defItem.label)}
                </InputLabel>)}
                <Select
                    value={item?.[defItem.name]}
                    className={classes.dropdown}
                    onChange={(event) => { handleEdit(event.target.value, item, index, defItem) }}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {!defItem.required && (<MenuItem value={null}>{"---"}</MenuItem>)}
                    {props.resources?.[defItem.dropdownResource]?.map(dRes => {
                        return (<MenuItem value={dRes["@id"]}>{defItem.dropdownResourceView(dRes)}</MenuItem>);
                    })}
                </Select>
            </FormControl>
        );
    };

    const renderOption = (item, index, defItem) => {
        return (
            <FormControl key={defItem.name} className={classes.formControl}>
                {defItem.label && (<InputLabel className={classes.inputLabel} shrink>
                    {translate(defItem.label)}
                </InputLabel>)}
                <Select
                    value={item?.[defItem.name]}
                    className={classes.dropdown}
                    onChange={(event) => { handleEdit(event.target.value, item, index, defItem); }}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    {!defItem.required && (<MenuItem value={null}>{"---"}</MenuItem>)}
                    {defItem.options?.map(option => {
                        return (<MenuItem value={option.value}>{translate(option.label)}</MenuItem>);
                    })}
                </Select>
            </FormControl>
        );
    };

    const renderCell = (item, index, defItem) => (
        <TableCell>
            {(() => {
                if (defItem.type === "dropdown") {
                    return renderResourceDropdown(item, index, defItem);
                }
                if (defItem.type === "custom") {
                    return renderCustomComponent(item, index, defItem);
                }
                if (defItem.type === "select") {
                    return renderOption(item, index, defItem);
                }
                return renderTextField(item, index, defItem);
            })()}
        </TableCell>
    )

    const renderRow = (item, index) =>
    (<EditableTableRow
        key={index}
        disableEdit
        disableDelete={index === 0}
        onDelete={() => handleDelete(index)}
        resourceId={index}
        minusIcon={props.minusIcon}
    >
        {props.resourceDef.map(defItem => renderCell(item, index, defItem))}
    </EditableTableRow>)

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table size={props.size}>
                <TableHead>
                    <TableRow>
                        {props.resourceDef.map((defItem) => {
                            return (<TableCell key={defItem.name}>
                                {translate(defItem.label)}
                            </TableCell>);
                        }
                        )}
                        <TableCell>
                            <IconButton aria-label="create" className={classes.smallIconButton} onClick={handleCreate}>
                                <AddCircleIcon fontSize="small" />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items && items.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                {renderRow(item, index)}
                            </React.Fragment>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>);
});

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
        loading: state.resources.loading,
        error: state.resources.error,
        resources: state.resources,
    };
};

const mapDispatchToProps = (dispatch) => ({
    list: (resource) => dispatch(list(resource)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CrudInput);

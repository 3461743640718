const users = [
    {
        type: "text",
        name: "firstname",
        rules: [{rule: "required", message: "validation.fieldInvalid"}],
        label: "user.firstname",
        required: true
    },
    {
        type: "text",
        name: "lastname",
        rules: [{rule: "required", message: "validation.fieldInvalid"}],
        label: "user.lastname",
        required: true
    },
];

export default users;
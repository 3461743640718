import React, { useCallback, useEffect, useRef, useState } from "react";
import { getTranslate } from "react-localize-redux";
import { list } from "../../actions/resources";
import { connect } from "react-redux";
import { Collapse, IconButton, makeStyles, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from "@material-ui/core";
import EditableTableRow from "../utils/EditableTableRow";
import ResourceModal from "../utils/ResourceModal";
import ResourceDeleteDialog from "../utils/ResourceDeleteDialog";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useWindowDimensions from "./useWindowDimension";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        "& .MuiTableCell-sizeSmall": {
            paddingTop: 3,
            paddingBottom: 3,
            paddingLeft: 8,
            paddingRight: 12,
        },
    },
    smallCell: {
        width: 20,
    },
    headCell: {
        display: "flex",
        flexDirection: "row"
    },
    headCellIcon: {
        paddingBottom: 3,
        width: 24,
        height: 24,
    },
    headCellCol: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    accordionCollapse: {
        padding: 10,
        paddingBottom: 30,
    },
}));


function CrudTable(props) {
    const classes = useStyles();
    const screenDimensions = useWindowDimensions();
    const screenWidth = screenDimensions.width;
    const maxWidth = props.maxWidth ?? 0.8 * (screenWidth - 240);

    const currentSearch = useRef();
    const currentFilter = useRef();

    const [editDialogId, setDialogId] = useState();
    const [deleteDialogId, setDeleteDialogId] = useState();
    const [createDialogVisible, setCreateDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [openedAccordions, setOpenedAccordions] = useState({});
    const [sortObject, setSortObject] = useState({ index: 0, order: 'desc' });
    const [areColumnsCollapsed, setAreColumnsCollapsed] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const { translate, search, list, error, resource, resources, resourceDef, filter, apiFilter } = props;

    const searchFilter = (listingItem) => {
        if (!(search && search.length > 0)) {
            return true;
        }
        let res = false;
        resourceDef.forEach(defItem => {
            const label = defItem.getLabel ?
                defItem.getLabel(listingItem[defItem.name], translate) :
                listingItem[defItem.name];
            if (label) {
                res = res || label.toString().match(new RegExp(`.*${search}.*`, 'i'));
            }
        });
        return res;
    }

    const listing = resources[resource]
        ?.filter((listingItem) => filter ? filter(listingItem) : true)
        ?.filter(searchFilter);

    const selectVisibleColumns = (pDef) => {
        let tableWidth = 0;
        let res = pDef.filter(defItem => !defItem.noShow).filter(pDef => {
            tableWidth += pDef.minWidth ?? 100;
            return tableWidth <= maxWidth;
        });
        if (res.length < pDef.filter(defItem => !defItem.noShow).length && !areColumnsCollapsed) {
            setAreColumnsCollapsed(true);
        } else if (res.length === pDef.filter(defItem => !defItem.noShow).length && areColumnsCollapsed) {
            setAreColumnsCollapsed(false);
        }
        return res;
    }

    const selectUnvisibleColumns = useCallback((pDef) => {
        let tableWidth = 0;
        let res = pDef.filter(defItem => !defItem.noShow).filter(pDef => {
            tableWidth += pDef.minWidth ?? 100;
            return tableWidth > maxWidth;
        });
        return res;
    }, [maxWidth]);

    useEffect(() => {
        list(resource, apiFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiFilter]);

    useEffect(() => {
        if (search && search.length > 0 && currentSearch.current !== search) {
            setPage(0);
        }
        const unvisibleColumns = selectUnvisibleColumns(resourceDef);
        if (!currentFilter.current && unvisibleColumns > 0) {
            setPage(0);
        }
        if (currentFilter.current && currentFilter.current !== filter) {
            setPage(0);
        }
        if (currentFilter.current !== filter) {
            currentFilter.current = filter;
        }
        if (currentSearch.current !== search) {
            currentSearch.current = search;
        }
    }, [search, list, setPage, filter, error, resourceDef, selectUnvisibleColumns]);

    const handleCreate = () => {
        props.onAdd ? props.onAdd() : setCreateDialogVisible(true);
    };

    const handleEdit = (id) => {
        if (props.onEdit) {
            return props.onEdit(id);
        }
        setDialogId(id);
        setEditDialogVisible(true);
    };

    const handleDelete = (id) => {
        if (!props.disableDeleteDialog) {
            setDeleteDialogId(id);
            setDeleteDialogVisible(true);
        } else {
            props.onDelete(id).then(() => list(resource));
        }
    };

    const handleAccordionsToggle = (accordionId) => {
        setOpenedAccordions({
            ...openedAccordions,
            [accordionId]: openedAccordions[accordionId] ? false : true,
        });
    };

    const renderCreateDialog = () => {
        return (
            <ResourceModal
                title={props.title}
                open={createDialogVisible}
                onClose={() => {
                    setCreateDialogVisible(false);
                }}
                resource={resource}
                data={resourceDef}
                minWidth={props.minDialogWidth}
                onResourceCreated={props.onResourceCreated}
                defaultValues={props.defaultCreateValues}
            />);
    }

    const renderEditDialog = () => {
        return (
            <ResourceModal
                title={props.title}
                resourceId={editDialogId}
                open={editDialogVisible}
                onClose={() => {
                    setEditDialogVisible(false);
                    setDialogId(undefined);
                }}
                data={resourceDef}
                minWidth={props.minDialogWidth}
            />);
    }

    const renderDeleteDialog = () => {
        return (
            <ResourceDeleteDialog
                resourceId={deleteDialogId}
                open={deleteDialogVisible}
                onClose={() => {
                    setDeleteDialogVisible(false);
                    setDeleteDialogId(undefined);
                }}
                onDelete={props.onDelete}
            />);
    }

    const renderCell = (listingItem, defItem, defIndex) => (
        <TableCell key={defIndex} style={{ minWidth: defItem.minWidth }}>
            {defItem.CustomComponent ? (<defItem.CustomComponent item={listingItem} />) : (defItem.getLabel ?
                defItem.getLabel(listingItem[defItem.name], translate) :
                listingItem[defItem.name])}
        </TableCell>
    )

    const renderRow = (listingItem) =>
    (<EditableTableRow
        key={`editable-${listingItem["@id"]}`}
        onEdit={() => handleEdit(listingItem["@id"])}
        onDelete={() => handleDelete(listingItem["@id"])}
        resourceId={listingItem["@id"]}
        minusIcon={props.minusIcon}
    >
        {(props.renderAccordion || areColumnsCollapsed) && (<TableCell className={classes.smallCell}>
            <IconButton aria-label="expand row" size="small" onClick={() => handleAccordionsToggle(listingItem["@id"])}>
                {openedAccordions[listingItem["@id"]] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </TableCell>)}
        {selectVisibleColumns(resourceDef).map((defItem, defIndex) => renderCell(listingItem, defItem, defIndex))}
    </EditableTableRow>)

    const renderAccordionRow = (listingItem) => {
        return (
            <TableRow key={`accordion-${listingItem["@id"]}`}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={openedAccordions[listingItem["@id"]]} timeout="auto" unmountOnExit>
                        <div className={classes.accordionCollapse}>
                            {props.renderAccordion(listingItem)}
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        );
    }


    const renderCollapsedRow = (listingItem) => {
        return (
            <TableRow key={`collapsed-${listingItem["@id"]}`}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={openedAccordions[listingItem["@id"]]} timeout="auto" unmountOnExit>
                        <div className={classes.accordionCollapse}>
                            <Table>
                                <TableBody>
                                    {selectUnvisibleColumns(resourceDef).map((defItem, defIndex) => (
                                        <TableRow>
                                            <TableCell>
                                                <b>
                                                    {translate(defItem.label)}
                                                </b>
                                            </TableCell>
                                            {renderCell(listingItem, defItem, defIndex)}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        );
    }

    const compareListingItems = (listingItemA, listingItemB) => {

        const interpretContent = (label) => {
            const asNumber = Number.parseFloat(label);
            if (asNumber && !Number.isNaN(asNumber)) {
                return asNumber;
            }

            const asDateTime = moment(label, "DD-MM-YYYY hh:mm");

            if (asDateTime && asDateTime.isValid()) {
                return asDateTime;
            }

            const asDate = moment(label, "DD-MM-YYYY");
            if (asDate && asDate.isValid()) {
                return asDate;
            }

            return label;
        }

        if (sortObject.index === undefined) {
            return (listingItemA["@id"] > listingItemB["@id"]) ? 1 : -1;
        } else {
            const defItem = resourceDef[sortObject.index];
            const labelA = defItem.getLabel ? defItem.getLabel(listingItemA[defItem.name], translate) : listingItemA[defItem.name];
            const labelB = defItem.getLabel ? defItem.getLabel(listingItemB[defItem.name], translate) : listingItemB[defItem.name];
            const sortSign = sortObject.order === 'asc' ? -1 : 1;
            if (!labelA) {
                return sortSign;
            } else if (!labelB) {
                return -sortSign;
            }
            return (interpretContent(labelA) > interpretContent(labelB)) ? sortSign : -sortSign;
        }
    }

    const rows = listing?.sort(compareListingItems);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.tableContainer}>
            {renderCreateDialog()}
            {renderEditDialog()}
            {renderDeleteDialog()}
            <Table size={props.size}>
                <TableHead>
                    <TableRow>
                        {(props.renderAccordion || areColumnsCollapsed) && <TableCell />}
                        {selectVisibleColumns(resourceDef).map((defItem, defIndex) => {
                            return (<TableCell key={defIndex} onClick={() =>
                                setSortObject({
                                    index: defIndex,
                                    order: defIndex === sortObject.index ? (sortObject.order === 'desc' ? 'asc' : 'desc') : 'desc',
                                })}>
                                <div className={classes.headCell}>
                                    <div className={classes.headCellCol}>
                                        {translate(defItem.label)}
                                    </div>
                                    {defIndex === sortObject.index && (<div className={`${classes.headCellCol} ${classes.headCellIcon}`}>
                                        {sortObject.order === 'asc' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </div>)}
                                </div>
                            </TableCell>);
                        }
                        )}
                        <TableCell>
                            <IconButton aria-label="create" className={classes.smallIconButton} onClick={handleCreate}>
                                <AddCircleIcon fontSize="small" />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows && ((rowsPerPage > 0 && !props.disablePagination)
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map(listingItem => {
                        return (
                            <React.Fragment key={listingItem["@id"]}>
                                {renderRow(listingItem)}
                                {props.renderAccordion && renderAccordionRow(listingItem)}
                                {!props.renderAccordion && selectUnvisibleColumns(resourceDef).length > 0 && renderCollapsedRow(listingItem)}
                            </React.Fragment>
                        )
                    })}
                </TableBody>
                {!props.disablePagination && <TableFooter>
                    {rows && <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>}
                </TableFooter>}
            </Table>
        </div>);
}

const mapStateToProps = (state) => {
    return {
        loading: state.resources.loading,
        error: state.resources.error,
        resources: state.resources,
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({
    list: (resource, apiFilter = "") =>
        dispatch(list(resource + ((apiFilter.length >= 0) ? "?" + apiFilter : ""))),

});

export default connect(mapStateToProps, mapDispatchToProps)(CrudTable);

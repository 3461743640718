import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

// theme CSS for React CSV Importer
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { CSVReader } from 'react-papaparse';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import { create, list, update } from "../../actions/resources";
import SimpleReactValidator from "simple-react-validator";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center"
    },
    csvReader: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        minWidth: 400,
    },
    csvReaderContent: {
        display: "flex",
        flexDirection: "row",
        minWidth: 400,
        justifyContent: "center",
    },
    startingRowField: {
        marginTop: 20,
        marginBottom: 10,
        maxWidth: 200,
        padding: 2,
    },
    dataGrid: {
        width: "100%",
        height: 400,
    }
}));

const CsvImporter = forwardRef((props, ref) => {
    const classes = useStyles();
    const { fixedValues, mainResource, identifier, columnsDef, resources, loading, error, list, create, update, translate, onCsvImported, onCsvDeleted } = props;
    const [jsonData, setJsonData] = useState();
    const [startingRow, setStartingRow] = useState(1);
    const [columnSelectValues, setColumnSelectValues] = useState([]);

    const renderColumnSelect = (id) => {
        return (<Select
            id="columns-select"
            value={columnSelectValues[id]}
            onChange={(event) => handleColumnSelectChange(event, id)}
            key={id}
        >
            <MenuItem value={-1}>{"---"}</MenuItem>
            {columnsDef.map((col, colInd) => {
                return (
                    <MenuItem key={colInd} value={colInd}>{translate(col.label)}</MenuItem>
                )
            })}
        </Select>);
    }

    const handleColumnSelectChange = (event, id) => {
        const oldValue = columnSelectValues[id];
        setColumnSelectValues(columnSelectValues.map((item, index) => {
            if (index === id && !(item >= 0 && columnsDef[item].required &&
                (event.target.value < 0 ||
                    !columnSelectValues.some(val => val === event.target.value)))) {
                return event.target.value;
            }
            if (item === event.target.value && event.target.value >= 0) {
                return oldValue;
            }
            return item;
        }));
    }

    const handleOnDrop = (data) => {
        setJsonData(data);
        if (onCsvImported) {
            onCsvImported();
        }
    };

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    const handleOnRemoveFile = (data) => {
        setJsonData(null);
        if (onCsvDeleted) {
            onCsvDeleted();
        }
    };

    const validateColumnData = (data, colInd) => {
        const definitionIndex = columnSelectValues?.[colInd];
        const definition = definitionIndex >= 0 && columnsDef[definitionIndex];
        let res = true;
        const validator = new SimpleReactValidator();
        definition.rules && definition.rules.forEach((rule) => {
            res = res && validator.check(data, rule);
        });
        if (data?.length) {
            if (definition.validate) {
                res = definition.validate(data) ? res : false;
            }
            if (resources?.[definition.resource]) {
                res = res && resources[definition.resource].some(item => {
                    if(definition.resourceField) {
                        return item[definition.resourceField]?.trim().toUpperCase() === data.trim().toUpperCase();
                    } else {
                        return definition.resourceFields && definition.resourceFields.some(rField => 
                            item[rField]?.trim().toUpperCase() === data.trim().toUpperCase())
                    }
                })
            }
        }
        return res;
    }

    const validateRowData = (rowData) => {
        let res = true;
        columnsDef.forEach((definition, defIndex) => {
            if (definition.required) {
                res = res && rowData?.some((data, colInd) => columnSelectValues?.[colInd] === defIndex);
                rowData.forEach((data, colInd) => {
                    if (columnSelectValues?.[colInd] === defIndex) {
                        res = res && validateColumnData(data, colInd);
                    }
                });
            }
        });

        return res;
    }

    const jsonDataParsed = jsonData && jsonData.slice(startingRow - 1);
    const numColumns = jsonDataParsed && jsonDataParsed.length > 0 && jsonDataParsed[0].data.length;
    const columns = numColumns > 0 &&
        [{ field: "validation", headerName: "-", width: 50, sortable: false, renderCell: (params) => params.value ? (<CheckIcon style={{ color: green[500] }} />) : (<CloseIcon color="secondary" />) }, ...jsonDataParsed[0].data.map((item, index) => {
            return {
                field: index,
                headerName: renderColumnSelect(index),
                width: 100,
                sortable: false,
                renderCell: (params) => validateColumnData(params.value, index) ?
                    <div>{params.value}</div> : (<div style={{ color: "red" }}>{params.value}</div>),
            }
        })];

    useEffect(() => {
        if (columnSelectValues.length === 0 && numColumns > 0) {
            setColumnSelectValues(columns.map((item, index) => {
                return index < columnsDef.length ? index : -1;
            }));
        }


        columnsDef.forEach((col) => {
            if (col.resource) {
                if (!resources?.[col.resource] && !loading && error === null) {
                    list(col.resource);
                }
            }
        });
        if (!resources?.[mainResource] && !loading && error === null) {
            list(mainResource);
        }
    }, [columnSelectValues.length, mainResource, numColumns, columns, columnsDef, resources, loading, error, list]);

    const submit = async (defaults) => {
        await Promise.allSettled(jsonDataParsed.map(async (item) => {
            let postData = { ...fixedValues } ?? {};
            postData = defaults ? { ...postData, ...defaults } : postData;
            if (validateRowData(item.data)) {
                columnSelectValues.forEach((value, valInd) => {
                    const definition = columnsDef[value];
                    if (definition && item.data[valInd] && validateColumnData(item.data[valInd], valInd)) {
                        if (definition.parse) {
                            postData[definition.name] = definition.parse(item.data[valInd]);
                        } else if (definition.resource && item.data) {
                            let foundResource;
                            if (definition.resourceField) {
                                foundResource = resources[definition.resource]
                                    .find(resItem => resItem[definition.resourceField] === item.data[valInd]);
                            }
                            if (definition.resourceFields) {
                                definition.resourceFields.forEach(rField => {
                                    if (!foundResource) {
                                        foundResource = resources[definition.resource].find(resItem => resItem[rField] === item.data[valInd]);
                                    }
                                });
                            }
                            if (!foundResource) {
                                if (definition.resourceField) {
                                    foundResource = resources[definition.resource]
                                        .find(resItem => resItem[definition.resourceField]?.trim().toUpperCase() === item.data[valInd].trim().toUpperCase());
                                }

                                if (definition.resourceFields) {
                                    definition.resourceFields.forEach(rField => {
                                        if (!foundResource) {
                                            foundResource = resources[definition.resource]
                                                .find(resItem => resItem[rField]?.trim().toUpperCase() === item.data[valInd].trim().toUpperCase());
                                        }
                                    });
                                }
                            }
                            postData[definition.name] = foundResource["@id"];
                        } else {
                            postData[definition.name] = item.data[valInd];
                        }
                    }
                });
                let foundResource = identifier && resources[mainResource].find(resource => resource[identifier] === postData[identifier]);
                if (!foundResource) {
                    foundResource = resources[mainResource].find(resource => resource[identifier].toString().toUpperCase() === postData[identifier].toString().toUpperCase());
                }
                if (foundResource) {
                    await update(foundResource["@id"], postData);
                } else {
                    await create(mainResource, postData);
                }
                console.log(postData);
            }
        }));
    }

    useImperativeHandle(ref, () => ({ submit }));

    return (
        <div className={classes.root}>
            <div className={classes.csvReader} style={jsonData ? { color: "black" } : {}}>
                <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    config={{}}
                    addRemoveButton
                    onRemoveFile={handleOnRemoveFile}
                >
                    <div className={classes.csvReaderContent}>
                        <span>Click to upload.</span>
                    </div>
                </CSVReader>
            </div>
            <TextField
                className={classes.startingRowField}
                id="starting-row"
                label={translate("importer.startingRow")}
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                value={startingRow}
                onChange={(event) => setStartingRow((isNaN(event.target.value) || event.target.value <= 0) ? startingRow : event.target.value)}
            />
            {jsonDataParsed && <div className={classes.dataGrid}>
                <DataGrid disableColumnMenu disableColumnSelector pageSize={5} columns={columns} rows={jsonDataParsed
                    .filter(item => item.data)
                    .map((item, index) => {
                        let res = { id: index };
                        item.data.forEach((element, ind) => {
                            res[ind] = element;
                        });

                        res["validation"] = validateRowData(item.data);

                        return res;
                    })} />
            </div>}
        </div>
    );
});

const mapStateToProps = (state) => {
    return {
        loading: state.resources.loading,
        error: state.resources.error,
        resources: state.resources,
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({
    list: (resource) =>
        dispatch(list(resource)),
    create: (resource, data) =>
        dispatch(create(resource, data)),
    update: (resourceId, data) =>
        dispatch(update(resourceId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CsvImporter);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, CssBaseline } from "@material-ui/core";
import AuthHeader from "./AuthHeader";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        color: 'whitesmoke',
        alignItems: "center",
        justifyContent: "center",
    },
    appBar: {
        backgroundColor: theme.palette['gray-dark'],
    },
    content: {
        display: "flex",
        marginTop: 150,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
}));

function AuthLayout(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar className={classes.appBar} position="fixed">
                <AuthHeader />
            </AppBar>
            <main
                className={classes.content}
            >
                {props.children}
            </main>
        </div>
    );
}

export default AuthLayout;

import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        'gray-dark': "#212529",
        background: {
            default: "#202020",
            paper: "#212529"
        },
    },
});

export default theme;
import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import TitledLayout from "../../theme/TitledLayout";
import CrudTable from "../utils/CrudTable";
import productReferencesDef from "../../definitions/productReferences";
import ProductReferencesImporter from "../importers/ProductReferencesImporter"
import { clearFilters } from "../../actions/filters";
import { list } from "../../actions/resources";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    importer: {
        display: "flex",
        flexDirection: "column",
        justifySelf: "flex-end",
        marginLeft: 10,
    },
    filterInput: {
        marginLeft: 10,
        marginRight: 20,
        marginBottom: 10,
        minWidth: 217,
    },
    filtersContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 30,
        backgroundColor: "#303030",
        padding: 10,
        borderRadius: 5,
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: 20,
    },
    flexCol: {
        display: "flex",
        flexDirection: "column",
    },
    filterInputLabel: {
        paddingLeft: 15,
    },
    crudTableContainer: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

function ProductReferences(props) {
    const [filtersValues, setFiltersValues] = useState({});
    const classes = useStyles();
    const { filters, clearFilters, translate, subcategories, productGroups, loading, error, listSubcategories, listProductGroups } = props;

    useEffect(() => {
        if (filters) {
            setFiltersValues(filters);
            clearFilters();
        }

        if (!subcategories && !loading && error === null) {
            listSubcategories();
        }

        if (!productGroups && !loading && error === null) {
            listProductGroups();
        }
    }, [filters, subcategories, loading, error, clearFilters, listProductGroups, listSubcategories, productGroups]);

    const categories = subcategories?.reduce((acc, value) => {
        return acc.some(item => item["@id"] === value.category["@id"]) ? acc : [...acc, value.category];
    }, []);

    const renderFilters = () => {
        return (
            <div className={classes.filtersContainer}>
                <div className={classes.flexCol}>
                    <div>
                        <TextField
                            className={classes.filterInput}
                            label={translate("general.search")}
                            value={filtersValues?.search ?? ""}
                            onChange={(event) => setFiltersValues({ ...filtersValues, search: event.target.value })}
                        />
                        {categories && (<FormControl>
                            <InputLabel className={classes.filterInputLabel} shrink>
                                {translate("category.category")}
                            </InputLabel>
                            <Select
                                className={classes.filterInput}
                                value={filtersValues?.category ?? ""}
                                onChange={(event) => setFiltersValues({
                                    ...filtersValues,
                                    category: event.target.value,
                                    subcategory: (filtersValues.category === event.target.value) ? filtersValues.subcategory : ""
                                })}
                                displayEmpty
                            >
                                <MenuItem value={""}>---</MenuItem>
                                {categories.map(category => <MenuItem key={category["@id"]} value={category["@id"]}>{category.name}</MenuItem>)}
                            </Select>
                        </FormControl>)}
                        {subcategories && (
                            <FormControl>
                                <InputLabel className={classes.filterInputLabel} shrink>
                                    {translate("subcategory.subcategory")}
                                </InputLabel>
                                <Select
                                    className={classes.filterInput}
                                    value={filtersValues?.subcategory ?? ""}
                                    onChange={(event) => setFiltersValues({
                                        ...filtersValues,
                                        subcategory: event.target.value,
                                        category: subcategories.find(subcategory => subcategory["@id"] === event.target.value)?.category["@id"] ?? ""
                                    })}
                                    displayEmpty
                                >
                                    <MenuItem value={""}>---</MenuItem>
                                    {subcategories
                                        .filter(subcategory => !filtersValues.category || (subcategory.category["@id"] === filtersValues.category))
                                        .map(subcategory => <MenuItem key={subcategory["@id"]} value={subcategory["@id"]}>{subcategory.name}</MenuItem>)}
                                </Select>
                            </FormControl>)}
                    </div>
                </div>
                <div className={classes.importer}>
                    <ProductReferencesImporter />
                </div>
            </div>
        )
    }

    const filter = (productReference) => {
        let res = true;
        if (filtersValues.category) {
            res = res && (productReference.subcategory?.category["@id"] === filtersValues.category);
        }
        if (filtersValues.subcategory) {
            res = res && (productReference.subcategory?.["@id"] === filtersValues.subcategory);
        }
        return res;
    }

    return (
        <TitledLayout title={translate("productReference.productReferences")}>
            <div className={classes.root}>
                {renderFilters()}
                <div className={classes.crudTableContainer}>
                    <CrudTable
                        title={translate("productReference.productReferences")}
                        size="small"
                        resource="/product_references"
                        resourceDef={productReferencesDef}
                        minDialogWidth={"50vw"}
                        withCsvImporter={true}
                        csvImporter={ProductReferencesImporter}
                        filter={filter}
                        search={filtersValues.search}
                    />
                </div>
            </div>
        </TitledLayout>
    )
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
        filters: state.filters.productReferences,
        subcategories: state.resources["/subcategories"],
        productGroups: state.resources["/product_groups"],
        loading: state.resources.loading,
        error: state.resources.error,
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearFilters: () => dispatch(clearFilters("productReferences")),
    listSubcategories: () => dispatch(list("/subcategories")),
    listProductGroups: () => dispatch(list("/product_groups")),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductReferences);

import moment from "moment";

const sales = [
    {
        type: "text",
        name: "registerId",
        rules: [{ rule: "numeric|integer|min:0,num", message: "validation.fieldInvalid" }],
        label: "sale.registerId",
        numeric: true,
    },
    {
        type: "text",
        name: "recipe",
        rules: [],
        label: "recipe.posId",
        getLabel: (recipe) => recipe?.posId,
        noEdit: true,
    },
    {
        type: "dropdown",
        name: "recipe",
        rules: [],
        getLabel: (recipe) => recipe?.name,
        dropdownResource: "/recipes",
        dropdownResourceView: (recipe) => recipe.name,
        label: "recipe.recipe",
        required: true,
    },
    {
        type: "text",
        name: "numberSold",
        rules: [{ rule: "numeric|integer|min:0,num", message: "validation.fieldInvalid" }],
        label: "sale.numberSold",
        numeric: true, 
    },
    {
        type: "text",
        name: "amount",
        rules: [{ rule: "numeric|min:0,num", message: "validation.fieldInvalid" }],
        label: "sale.amount",
        numeric: true,
    },
    {
        type: "datetime",
        name: "date",
        rules: [{ rule: "required", message: "validation.fieldInvalid" }],
        defaultValue: new Date(),
        label: "general.date",
        getLabel: (item) => moment(item).format("DD-MM-YYYY HH:mm"),
    },
];

export default sales;
import React, { useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, makeStyles } from "@material-ui/core";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import CsvImporter from "./CsvImporter";


const useStyles = makeStyles((theme) => ({
    root: {
    },
    content: {
        paddingLeft: 50,
        paddingRight: 50,
    },
    button: {

    }
}));

const columnsDef = [
    {
        name: "productReference",
        label: "productReference.productReference",
        required: true,
        resource: "/product_references",
        resourceFields: ["productCode", "name"],
        rules: ["required"],
    },
    {
        name: "location",
        label: "location.location",
        required: true,
        resource: "/locations",
        resourceField: "name",
    },
    {
        name: "quantity",
        label: "general.quantity",
        rules: ["numeric|min:0,num", "required"],
        required: true,
        parse: Number.parseFloat,
    },
    {
        name: "caseSize",
        label: "inventory.caseSize",
        rules: ["numeric|min:0,num"],
        parse: Number.parseFloat,
    },
    {
        name: "unitType",
        label: "unitType.unitType",
        parse: (str) => str.trim().toUpperCase(),
    },
    {
        name: "amount",
        label: "sale.amount",
        rules: ["numeric|min:0,num"],
        parse: (str) => Number.parseFloat(Number.parseFloat(str).toFixed(2)),
    },
];

function InventoryItemsImporter(props) {
    const classes = useStyles();
    const { translate } = props;
    const [dialogVisible, setDialogVisible] = useState(false);
    const [fullscreen, setFullScreen] = useState(false);
    const childRef = useRef();

    const handleSave = async (event) => {
        await childRef.current.submit({ inventory: props.inventoryId })
        setDialogVisible(false);
        setFullScreen(false);
        window.location.reload(true);
    }

    return (
        <>
            <Dialog
                open={dialogVisible}
                onClose={() => { props.onClose(); setFullScreen(false); }}
                className={classes.root}
                fullScreen={fullscreen}
            >
                {props.title && <DialogTitle id="form-dialog-title">
                    {props.title}
                </DialogTitle>}
                <DialogContent className={classes.content}>
                    <CsvImporter
                        ref={childRef}
                        columnsDef={columnsDef}
                        mainResource="/inventory_items"
                        onCsvImported={() => setFullScreen(true)}
                        onCsvDeleted={() => setFullScreen(false)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogVisible(false)} color="secondary">
                        {translate("button.cancel")}
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        {translate("button.save")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                onClick={() => setDialogVisible(true)}
                variant="contained"
                className={classes.button}
            >
                {translate("importer.csvImport")}
            </Button>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryItemsImporter);

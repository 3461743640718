import React from "react";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { addFilters, clearFilters } from "../actions/filters";

function CategoryLink(props) {
    const { item, addFilters } = props;
    const history = useHistory();

    const handleClick = (event) => {
        event.preventDefault();
        addFilters({ category: item["@id"] });
        history.push("/product_references");
    }

    return (
        <Link href={"/product_references"} onClick={handleClick} style={{ color: "whitesmoke" }}>
            {item.name}
        </Link>
    )

}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => ({
    clearFilters: () => dispatch(clearFilters("productReferences")),
    addFilters: (filters) => dispatch(addFilters("productReferences", filters)),
});

export const ConnectedCategoryLink = connect(mapStateToProps, mapDispatchToProps)(CategoryLink);

const categories = [
    {
        type: "text",
        name: "name",
        rules: [{rule: "required", message: "validation.fieldInvalid"}],
        label: "general.name",
        required: true,
        CustomComponent: ConnectedCategoryLink,
    },
];

export default categories;
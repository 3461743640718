import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../actions/session";

function Logout(props) {
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await props.logout();
      setTimeout(() => history.push('/login'), 1000);
    })();
  });

  return <div></div>;
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(Logout);

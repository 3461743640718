import { Avatar, List, ListItem, ListItemText, ListItemAvatar, makeStyles, Typography, Divider } from "@material-ui/core";
import LocalDrinkIcon from "@material-ui/icons/LocalDrink";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import WidgetsIcon from '@material-ui/icons/Widgets';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import BusinessIcon from '@material-ui/icons/Business';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DescriptionIcon from '@material-ui/icons/Description';
import React from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import MainLayout from "../../theme/MainLayout";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  menuList: {
    width: '100%',
    maxWidth: 360,
    paddingBottom: 0,
  },
  menuLink: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  menuCard: {
    backgroundColor: "#101214",
    paddingTop: 10,
    paddingLeft: 20,
    borderRadius: 20,
    maxWidth: 400,
    width: 400,
    boxShadow: "10px 5px 5px black",
  },
  menuRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  menuCardTitle: {
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: 30,
    marginLeft: "10%",
    marginRight: "10%",
  },
  reportMenuCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  reportMenuLink: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function Welcome(props) {
  const classes = useStyles();
  const { translate } = props;

  function menuItem(icon, text, link) {
    return <Link to={link} className={classes.menuLink}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            {icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={text} />
      </ListItem>
      <Divider />
    </Link>
  }

  function catalogMenu() {
    return (
      <div className={classes.menuCard}>
        <Typography className={classes.menuCardTitle} variant="h5">{translate("general.catalog")}</Typography>
        <Divider />
        <List className={classes.menuList}>
          {menuItem(<MenuBookIcon />, translate("productReference.productReferences"), "/product_references")}
          {menuItem(<LocalDrinkIcon />, translate("productGroup.productGroups"), "/product_groups")}
          {menuItem(<WidgetsIcon />, translate("category.categories"), "/categories")}
          {menuItem(<LocalBarIcon />, translate("recipe.recipes"), "/recipes")}
        </List>
      </div>
    );
  }

  function locationsAndVendorsMenu() {
    return (
      <div className={classes.menuCard}>
        <Typography className={classes.menuCardTitle} variant="h5">{`${translate("location.locations")} & ${translate("vendor.vendors")}`}</Typography>
        <Divider />
        <List className={classes.menuList}>
          {menuItem(<BusinessIcon />, translate("location.locations"), "/locations")}
          {menuItem(<LocalShippingIcon />, translate("vendor.vendors"), "/vendors")}
        </List>
      </div>
    );
  }

  function inventoriesAndSalesMenu() {
    return (
      <div className={classes.menuCard}>
        <Typography className={classes.menuCardTitle} variant="h5">{`${translate("inventory.inventories")} & ${translate("sale.sales")}`}</Typography>
        <Divider />
        <List className={classes.menuList}>
          {menuItem(<DescriptionIcon />, translate("inventory.inventories"), "/inventories")}
          {menuItem(<ReceiptIcon />, translate("invoice.invoices"), "/invoices")}
          {menuItem(<LocalShippingIcon />, translate("sale.sales"), "/sales")}
        </List>
      </div>
    );
  }

  function reportMenu() {
    return <div className={classes.menuCard + " " + classes.reportMenuCard}>
      <Link to={"/reports"} className={classes.menuLink}>
        <ListItem className={classes.reportMenuLink}>
          <BusinessIcon />
          <ListItemText primary={translate("report.reports")} />
        </ListItem>
      </Link>
    </div>
  }

  return (
    <MainLayout>
      <div className={classes.menuContainer}>
        <div className={classes.menuRowContainer}>
          {catalogMenu()}
          {locationsAndVendorsMenu()}
        </div>
        <div className={classes.menuRowContainer}>
          {inventoriesAndSalesMenu()}
          {reportMenu()}
        </div>
      </div>
    </MainLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    translate: getTranslate(state.localize),
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);

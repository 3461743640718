export const addFilters = (label, filters) => {
    return {
        type: "ADD_FILTERS",
        value: { label: label, filters: filters },
    };
}

export const clearFilters = (label) => {
    return {
        type: "CLEAR_FILTERS",
        value: label,
    };
}

import { makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import AuthLayout from "../../../theme/auth/AuthLayout";
import LoginForm from "../../auth/LoginForm";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    loginForm: {
        backgroundColor: "#202020",
        width: "60%",
        maxWidth: 700,
        padding: "60px 80px 60px 80px",
        boxShadow: "10px 5px black",
        borderRadius: 10,
    }
}));

function LoginPage() {
    const classes = useStyles();
    const history = useHistory();

    return (
        <AuthLayout className={classes.root}>
            <div className={classes.loginForm}>
                <LoginForm onAuthenticationSuccess={() => history.push("/")} />
            </div>
        </AuthLayout>
    )
}

export default LoginPage;
import { FormControl, makeStyles, Button } from "@material-ui/core";
import React, { useRef } from "react";
import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import fetch from "../../utils/apiFetch";
import { readString } from "react-papaparse";
import { list, create } from "../../actions/resources";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        maxWidth: 300,
    },
}));

function MetroPdfUploader(props) {
    const classes = useStyles();
    const hiddenFileInput = useRef(null);

    const { translate, createProductReference, createInventory, createInventoryItem, listProductReferences, listLocations, listVendors, listInventories, listInventoryItems } = props;
  
    const handleUploaderClick = event => {
      hiddenFileInput.current.click();
    };

    const areCodesSame = (codeA, codeB) => {
        return codeA && codeB && codeA.trim().toUpperCase() === codeB.trim().toUpperCase();
    }

    const trimObject = (obj) => Object.fromEntries(Object.entries(obj).map(([key, value]) => value ? [key, value.trim()] : undefined));

    const parseUnit = (value) => {
        const upValue = value.toUpperCase();
        switch (upValue) {
            case "ML": case "MILLILITER":
                return "MILLILITERS";
            case "L": case "LITER":
                return "LITERS";
            case "G": case "GRAM":
                return "GRAMS";
            case "KG": case "KILOGRAM":
                return "KILOGRAMS";
            default:
                return "MILLILITERS";
        }
    }

    const getDateFromInvoiceName = (filename) => {
        const slicedFilename = filename.split("_invoice");
        if (slicedFilename.length < 2) {
            return moment().toISOString();
        }
        if(slicedFilename[0] < 14) {
            return moment().toISOString();
        }
        const date = slicedFilename[0].slice(-14);
        const year = date.slice(0, 4);
        const month = date.slice(4, 6);
        const day = date.slice(6, 8);
        const hour = date.slice(8, 10);
        const minute = date.slice(10, 12);
        const second = date.slice(12, 14);

        return year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + second + "+02:00";
    }

    const uploadInvoiceFile = (event) => {
        const files = event.target.files;
        let formData = new FormData();

        formData.append('file', files[0]);
        /*
          Make the request to the POST /metro_pdf_invoices URL
        */
        fetch('/metro_pdf_invoices',
            {
                method: "POST",
                body: formData,
            }
        ).then((res) => res.json()).then(json => {
            alert(json.scriptReport);
            const productReferencesContent = readString(json.productReferencesCsvContent, { header: true }).data.slice(0, -1);
            const invoiceContent = readString(json.csvContent, { header: true }).data.slice(0, -1);
            listProductReferences().then((productReferences) => {
                const productReferencesToImport = productReferencesContent
                    .filter(productReferenceToImport => !productReferences.some(productReference => areCodesSame(productReference.productCode, productReferenceToImport["Code barre"])))
                    .filter((productReferenceToImport, productReferenceToImportIndex) => !productReferencesContent.some((productReference, productReferenceIndex) => productReferenceIndex < productReferenceToImportIndex && areCodesSame(productReference["Code barre"], productReferenceToImport["Code barre"])))
                    .map(productReferenceToImport => trimObject(productReferenceToImport));
                Promise.allSettled(productReferencesToImport.map(productReferenceToImport => {
                    return createProductReference({
                        "name": productReferenceToImport["Nom produit"],
                        "productCode": productReferenceToImport["Code barre"],
                        "capacity": Number.parseFloat(productReferenceToImport["Volume Contenant"]),
                        "capacityUnit": parseUnit(productReferenceToImport["Unité"]),
                    });
                })).then(() => {
                    listVendors().then((vendors) => {
                        listLocations().then((locations) => {
                            listProductReferences().then((productReferencesUpdated) => {
                                const filenameWithoutExtension = json.contentUrl.split("/").pop().slice(0, -4);
                                let invoiceNumber = filenameWithoutExtension.split("_");
                                invoiceNumber = invoiceNumber.length > 3 ? invoiceNumber[2].padStart(3, "0") + "-" + invoiceNumber[3] : null;
                                createInventory({
                                    type: "INVOICE",
                                    description: filenameWithoutExtension.length > 14 ? filenameWithoutExtension.slice(14) : filenameWithoutExtension,
                                    vendor: vendors.find(vendor => vendor.name.trim().toUpperCase() === "METRO")?.["@id"] ?? vendors[0]?.["@id"],
                                    invoiceNumber: invoiceNumber,
                                    date: getDateFromInvoiceName(filenameWithoutExtension),
                                }).then((createdInventory) => {
                                    Promise.all(invoiceContent.map(invoiceItem => {
                                        return createInventoryItem(
                                            {
                                                inventory: createdInventory["@id"],
                                                location: locations.find(location => location.name.trim().toUpperCase() === invoiceItem["Emplacement"].trim().toUpperCase())?.["@id"] ?? locations[0]?.["@id"],
                                                caseSize: Number.parseInt(invoiceItem["Taille de caisse"]),
                                                quantity: Number.parseFloat(invoiceItem["Quantité"]),
                                                unitType: invoiceItem["Type de quantité"].toUpperCase(),
                                                productReference: (productReferencesUpdated.find(pr => areCodesSame(pr.productCode, invoiceItem["Code barre"])) ?? productReferencesUpdated.find(pr => areCodesSame(pr.name, invoiceItem["Dénomination"])))["@id"],
                                                amount: Number.parseFloat(invoiceItem["Prix total"]),
                                            }
                                        );
                                    })).then(() => {
                                        listInventories();
                                        listInventoryItems();
                                    });
                                });
                            });
                        });
                    });
                });
            })
        })
            .catch(function () {
                alert("Unable to correctly parse file");
            });
    }

    return <FormControl className={classes.root}>
        <Button variant={"contained"} onClick={handleUploaderClick}>
            {translate("invoice.uploadMetroPdf")}
        </Button>
        <input id="file" type="file" ref={hiddenFileInput} onChange={uploadInvoiceFile} style={{display: "none"}} />
    </FormControl>
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
        inventories: state.resources.inventories,
    };
};

const mapDispatchToProps = (dispatch) => ({
    createProductReference: (data) => dispatch(create("/product_references", data)),
    createInventory: (data) => dispatch(create("/inventories", data)),
    createInventoryItem: (data) => dispatch(create("/inventory_items", data)),
    listProductReferences: () => dispatch(list("/product_references")),
    listVendors: () => dispatch(list("/vendors")),
    listLocations: () => dispatch(list("/locations")),
    listInventories: () => dispatch(list("/inventories")),
    listInventoryItems: () => dispatch(list("/inventory_items")),
});

export default connect(mapStateToProps, mapDispatchToProps)(MetroPdfUploader);

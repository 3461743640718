import fetch from "../utils/apiFetch";
import { logout } from "./session";

export function create(resource, data) {
    return (dispatch) => {
        dispatch({ type: "CREATE_RESOURCE_LOADING", value: resource });
        return fetch(resource, { body: JSON.stringify(data), method: "POST" })
            .then((res) => {
                if (res.ok) { return res.json() } else {
                    if (res.status === 401) {
                        console.log("Unauthorized 401");
                        dispatch(logout());
                    } else {
                        throw new Error("Error fetching resource");
                    }
                }
            })
            .then((json) => {
                dispatch({
                    type: "CREATE_RESOURCE",
                    value: { resource: resource, created: json },
                });
                return json;
            }).catch(
                err => dispatch({ type: "RESOURCE_ERROR", value: err })
            );
    }
}

export function update(id, data) {
    return (dispatch) => {
        dispatch({ type: "UPDATE_RESOURCE_LOADING", value: id });
        return fetch(id, { body: JSON.stringify(data), method: "PUT" })
            .then((res) => {
                if (res.ok) { return res.json() } else {
                    if (res.status === 401) {
                        console.log("Unauthorized 401");
                        dispatch(logout());
                    } else {
                        throw new Error("Error fetching resource");
                    }
                }
            })
            .then((json) => {
                dispatch({
                    type: "UPDATE_RESOURCE",
                    value: { id: id, data: json },
                });
                return json;
            }).catch(
                err => dispatch({ type: "RESOURCE_ERROR", value: err })
            );;
    }
}

export function remove(id) {
    return (dispatch) => {
        dispatch({ type: "REMOVE_RESOURCE_LOADING", value: id });
        return fetch(id, { method: "DELETE" })
            .then((res) => {
                if (res.ok) {
                    return dispatch({
                        type: "DELETE_RESOURCE",
                        value: { id: id },
                    })
                } else {
                    if (res.status === 401) {
                        console.log("Unauthorized 401");
                        dispatch(logout());
                    } else {
                        throw new Error("Error fetching resource");
                    }
                }
            })
            .catch(
                err => dispatch({ type: "RESOURCE_ERROR", value: err })
            );;
    }
}

export function list(resource, resourceName = null) {
    return (dispatch) => {
        dispatch({ type: "LIST_RESOURCE_LOADING", value: resource })
        return fetch(resource, { method: "GET" })
            .then((res) => {
                if (res.ok) { return res.json() } else {
                    if (res.status === 401) {
                        console.log("Unauthorized 401");
                        dispatch(logout());
                    } else {
                        throw new Error("Error fetching resource");
                    }
                }
            })
            .then((json) => {
                dispatch({
                    type: "LIST_RESOURCE",
                    value: json,
                    name: resourceName,
                });
                return json["hydra:member"];
            }).catch(
                err => { dispatch({ type: "RESOURCE_ERROR", value: err }); console.log(err) }
            );
    }
}

export function getById(resourceId) {
    return (dispatch) => {
        dispatch({ type: "GET_BY_ID_RESOURCE_LOADING", value: resourceId })
        return fetch(resourceId, { method: "GET" })
            .then((res) => {
                if (res.ok) { return res.json() } else {
                    if (res.status === 401) {
                        console.log("Unauthorized 401");
                        dispatch(logout());
                    } else {
                        throw new Error("Error fetching resource");
                    }
                }
            })
            .then((json) => {
                dispatch({
                    type: "GET_BY_ID_RESOURCE",
                    value: json,
                });
                return json;
            }).catch(
                err => { dispatch({ type: "RESOURCE_ERROR", value: err }); console.log(err) }
            );
    }
}
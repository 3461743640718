import units, { parseLabel as parseUnitLabel } from "./units"

const productReferences = [
    {
        type: "text",
        name: "productCode",
        rules: [],
        label: "productReference.productCode",
        required: false,
    },
    {
        type: "text",
        name: "name",
        rules: [{ rule: "required", message: "validation.fieldInvalid" }],
        label: "general.name",
        required: true,
        minWidth: 200,
    },
    {
        type: "none",
        name: "subcategory",
        rules: [],
        getLabel: (subcategory) => subcategory?.category?.name,
        label: "category.category",
        noEdit: true,
    },
    {
        type: "dropdown",
        name: "subcategory",
        rules: [],
        getLabel: (subcategory) => subcategory?.name,
        dropdownResource: "/subcategories",
        dropdownResourceView: (subcategory) => subcategory?.name,
        label: "subcategory.subcategory",
        required: false,
    },
    {
        type: "dropdown",
        name: "productGroup",
        rules: [],
        getLabel: (productGroup) => productGroup?.name,
        dropdownResource: "/product_groups",
        dropdownResourceView: (productGroup) => productGroup["name"],
        label: "productGroup.productGroup",
        required: false,
    },
    {
        type: "text",
        name: "capacity",
        rules: [{ rule: "numeric|min:0,num", message: "validation.fieldInvalid" }],
        label: "productReference.capacity",
        numeric: true,
    },
    {
        type: "select",
        name: "capacityUnit",
        rules: [],
        getLabel: parseUnitLabel,
        label: "productReference.capacityUnit",
        options: units
    },
    {
        type: "text",
        name: "doseCapacity",
        rules: [{ rule: "numeric|min:0,num", message: "validation.fieldInvalid" }],
        label: "productReference.doseCapacity",
        numeric: true,
    },
    {
        type: "select",
        name: "doseCapacityUnit",
        rules: [],
        getLabel: parseUnitLabel,
        label: "productReference.doseCapacityUnit",
        options: units
    },
    {
        type: "text",
        name: "empty",
        rules: [{ rule: "numeric|min:0,num", message: "validation.fieldInvalid" }],
        label: "productReference.empty",
        numeric: true,
        required: true,
    },
    {
        type: "text",
        name: "full",
        rules: [{ rule: "numeric|min:0,num", message: "validation.fieldInvalid" }],
        label: "productReference.full",
        numeric: true,
        required: true,
    },
];

export default productReferences;

export const productReferencesShort = [
    {
        type: "text",
        name: "name",
        label: "general.name",
        rules: [{rule: "required", message: "validation.fieldInvalid"}],
        required: true,
        noEdit: true,
    },
    {
        type: "text",
        name: "productCode",
        label: "productReference.productCode",
        noEdit: true,
    },
    {
        type: "dropdown",
        name: "productGroup",
        rules: [],
        dropdownResource: "/product_groups",
        dropdownResourceView: (productGroup) => productGroup["name"],
        label: "productGroup.productGroup",
        required: false,
        noShow: true,
    },
];
